import { FC, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material'

import { LabelBorder } from 'components/Label/LabelBorder'

import { useAppSelector } from 'store/store'
import { selectLabelFontSize } from 'store/configSlice'
import { selectProjection } from 'store/mapSlice'

const LabelItemWrapper = styled('g')<{ offset: number }>`
  transform: translateX(${({ offset }) => offset}px);

  &:hover rect {
    stroke: ${({ theme }) => theme.palette.grayscale.white};
  }
`

const LabelItemStyled = styled('text')`
  fill: ${({ theme }) => theme.palette.grayscale.white};
`

const LabelItem: FC<ChildrenInterface & { onClick: VoidFunction; offset: number }> = ({
  children,
  onClick,
  offset,
}) => {
  const labelFont = useAppSelector(selectLabelFontSize)
  const projection = useAppSelector(selectProjection)
  const itemRef = useRef<SVGTextElement>(null)
  const [bounds, setBounds] = useState({ w: 0, h: 0 })

  useEffect(() => {
    if (itemRef.current) {
      const { width, height } = itemRef.current.getBoundingClientRect()
      setBounds({ w: width, h: height + 2 })
    }
  }, [labelFont, children, projection])

  return (
    <LabelItemWrapper offset={offset} onClick={onClick}>
      <LabelItemStyled ref={itemRef} fontSize={labelFont}>
        {children}
      </LabelItemStyled>
      <LabelBorder x={0} y={-1 * labelFont + 2} width={bounds.w} height={bounds.h > 4 ? bounds.h - 4 : bounds.h} />
    </LabelItemWrapper>
  )
}

export default LabelItem
