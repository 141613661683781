export const getAltitudeValue = (altitude: number): string => {
  let result = (altitude / 100).toFixed()
  switch (result.length) {
    case 1:
      result = `00${result}`
      return result
    case 2:
      result = `0${result}`
      return result
    default:
      return result
  }
}

export const getHeadingValue = (heading: number): string => {
  let result = heading.toFixed()
  switch (result.length) {
    case 1:
      result = `00${result}`
      return result
    case 2:
      result = `0${result}`
      return result
    default:
      return result
  }
}

export const getRateText = (rate: number): string => {
  let result = (rate / 10).toFixed()
  switch (result.length) {
    case 1:
      result = `0${result}`
      return result
    default:
      return result
  }
}
