import { Navigate, Route, Routes } from 'react-router-dom'

import { positions } from 'constants/constants'

import HomePage from 'pages/HomePage'
import CWPPage from 'pages/CWPPage'

import { useAppSelector } from 'store/store'
import { selectLoggedIn } from 'store/authSlice'

const AppRouter = () => {
  const isLoggedIn = useAppSelector(selectLoggedIn)

  return isLoggedIn ? (
    <Routes>
      <Route element={<HomePage />} path="/" />
      {Object.keys(positions).map(route => (
        <Route element={<CWPPage />} path={`/${route}`} key={route} />
      ))}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  ) : (
    <Routes>
      <Route element={<HomePage />} path="/" />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default AppRouter
