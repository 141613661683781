import { FC } from 'react'
import { GeoProjection } from 'd3-geo'

import { useAppSelector } from 'store/store'
import { selectHistoryValue } from 'store/configSlice'

import { geoToScreenCoordinates } from 'utils/map'

// @ts-ignore
const Trail: FC<{ track: TrackInterface; projection: GeoProjection; tick: number }> = ({ track, projection, tick }) => {
  const trailSize = useAppSelector(selectHistoryValue)

  const getCircle = ([x, y]: number[]) => (
    <circle key={`trail-${x}-${y}+${Math.random()}`} cx={x} cy={y} r={1} fill="#72b4ff" />
  )

  return trailSize > 0
    ? new Array(trailSize)
        .fill(null)
        .map((_, i) => i + 1)
        .map(position => {
          if (track.tickPositions[tick - position]) {
            const centerCoordinates: number[] = geoToScreenCoordinates(
              track.tickPositions[tick - position].cP,
              projection,
            )
            return getCircle(centerCoordinates)
          }
          return null
        })
    : null
}

export default Trail
