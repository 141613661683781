import { FC, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ToggleButton } from '@mui/material'

import MenuWrapper from 'components/Windows/MenuWrapper'
import ClockButton from 'components/Windows/components/ClockButton'
import SectorButton from 'components/Windows/components/SectorButton'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectInfoPanelWindows, toggleWindow } from 'store/windowsSlice'

const InfoPanel: FC = () => {
  const dispatch = useAppDispatch()
  const { aswToolbox1Window, exerciseWindow, activeWindow, historicalWindow, workloadWindow, mainMenu } =
    useAppSelector(selectInfoPanelWindows)

  const navigate = useNavigate()

  const handleToggle = (_: MouseEvent<HTMLElement>, value: string) => {
    dispatch(toggleWindow(value))
  }

  const goHome = () => {
    navigate('/home')
  }

  return (
    <MenuWrapper>
      <Button onClick={goHome}>Home</Button>
      <ToggleButton value="aswToolbox1Window" selected={aswToolbox1Window} onClick={handleToggle}>
        ASW Toolbox
      </ToggleButton>
      <Button>New exercise</Button>
      <ToggleButton value="exerciseWindow" selected={exerciseWindow} onClick={handleToggle}>
        Exercises
      </ToggleButton>
      <ToggleButton value="activeWindow" selected={activeWindow} onClick={handleToggle}>
        Active
      </ToggleButton>
      <ToggleButton value="historicalWindow" selected={historicalWindow} onClick={handleToggle}>
        Historical
      </ToggleButton>
      <ToggleButton value="workloadWindow" selected={workloadWindow} onClick={handleToggle}>
        Workload
      </ToggleButton>
      <Button>New exercise</Button>
      <SectorButton />
      <ClockButton />
      <ToggleButton value="mainMenu" selected={mainMenu} onClick={handleToggle}>
        Menu
      </ToggleButton>
    </MenuWrapper>
  )
}

export default InfoPanel
