import { createTheme, ThemeOptions } from '@mui/material'

const defTheme = createTheme()

/*
  zIndex - for:
  - MapLayer - 0
  - TrackLayer - 100
  - InfoPanel/MainMenu - Appbar(default)
  - Other windows - Drawer
 */

export const palette = {
  primary: {
    main: '#464646',
    light: '#727272',
    dark: '#282828',
    contrastText: '#c1c1c1',
  },
  secondary: {
    light: '#64c8ff',
    main: '#79b6d7',
    dark: '#72b4ff',
  },
  success: {
    light: '#0E8C57',
    main: '#008000',
    dark: '#065f06',
  },
  info: {
    main: '#c1c1c1',
  },
  // warning: {
  //   main: '#E7A424',
  //   light: '#FCE3B1',
  //   dark: '#8C6415',
  // },
  error: {
    main: '#C13600',
    light: '#FB6A6A',
    dark: '#882025',
  },
  background: {
    default: '#464646',
    paper: '#464646',
  },
  sector: {
    fill: '#4e4a4a',
    border: '#969696',
  },
  workload: {
    workload: '#00ff22',
    flights: '#0055ff',
    conflicts: '#ff0000',
  },
  grayscale: {
    black: '#000000',
    white: '#ffffff',
    medium: '#444',
  },
}

export const theme: ThemeOptions = {
  palette,
  components: {
    MuiButton: {
      defaultProps: { color: 'primary', variant: 'contained', size: 'medium' },
      styleOverrides: {
        containedPrimary: {
          width: 'fit-content',
          userSelect: 'none',
          borderRadius: defTheme.spacing(2),
          border: `1px solid ${palette.primary.dark}`,
          minWidth: defTheme.spacing(15),
          fontSize: '12px',
          lineHeight: 1,
          fontWeight: 400,
          boxShadow: 'none',
          padding: `${defTheme.spacing(1)} ${defTheme.spacing(2)}`,
          '&.Mui-disabled': {
            color: palette.primary.light,
          },
        },
        containedSizeLarge: {
          fontSize: defTheme.spacing(2),
          padding: `${defTheme.spacing(2)} ${defTheme.spacing(3)}`,
          textAlign: 'center',
        },
        containedSizeSmall: {
          minWidth: 0,
          padding: defTheme.spacing(0.75),
          fontSize: defTheme.spacing(1),
          lineHeight: 0.75,
        },
        text: {
          color: palette.primary.contrastText,
        },
        fullWidth: { flex: 1, width: '100%' },
      },
    },
    MuiToggleButton: {
      defaultProps: { color: 'primary', size: 'medium' },
      styleOverrides: {
        root: {
          width: 'fit-content',
          userSelect: 'none',
          borderRadius: defTheme.spacing(2),
          border: `1px solid ${palette.primary.dark}`,
          minWidth: defTheme.spacing(15),
          fontSize: '12px',
          lineHeight: 1,
          fontWeight: 400,
          boxShadow: 'none',
          padding: `${defTheme.spacing(1)} ${defTheme.spacing(2)}`,
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,

          '&.Mui-selected': {
            backgroundColor: palette.primary.light,
            color: palette.primary.contrastText,

            '&:hover': { backgroundColor: palette.primary.dark },
          },
          '&:hover': {
            backgroundColor: palette.primary.dark,
          },

          '&.MuiToggleButton-info': {
            backgroundColor: palette.error.main,
            '&:hover': { backgroundColor: palette.error.dark },

            '&.Mui-selected': {
              backgroundColor: palette.success.main,

              '&:hover': { backgroundColor: palette.success.dark },
            },
          },
        },
        sizeSmall: { minWidth: defTheme.spacing(4), padding: defTheme.spacing(1), width: 'fit-content' },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: defTheme.spacing(1),
            border: `1ps solid ${palette.primary.dark}`,
            backgroundColor: palette.primary.contrastText,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: { root: { color: palette.primary.dark, '&.Mui-focused': { color: palette.primary.dark } } },
    },
    MuiInputBase: { styleOverrides: { inputSizeSmall: { padding: defTheme.spacing(0, 1.25) } } },
    MuiAppBar: {
      defaultProps: { position: 'fixed', color: 'primary' },
      styleOverrides: {
        colorPrimary: {
          backgroundColor: palette.grayscale.medium,
        },
      },
    },
    MuiRadio: {
      defaultProps: { size: 'small', color: 'secondary' },
      styleOverrides: {
        root: {
          color: palette.primary.contrastText,
          padding: defTheme.spacing(0.5),
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: palette.primary.contrastText,
        },
      },
    },
    MuiSlider: {
      defaultProps: { size: 'small', color: 'secondary' },
      styleOverrides: {
        colorSecondary: {
          display: 'block',
          margin: '0 auto',
          maxWidth: '96%',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: palette.primary.contrastText,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
        color: 'info',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: { minWidth: 150 },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingX: defTheme.spacing(1.5),
          minWidth: 150,
        },
      },
    },
  },
}
