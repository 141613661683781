import { GeoProjection } from 'd3-geo'
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getProjection } from 'utils/map'
import { getMapDataRequest } from 'services/mapService'

import type { RootState } from 'store/store'

interface MapStateInterface {
  geometry: {
    sectors: SectorsInterface
    lines: LinesInterface
    points: PointsInterface
    freq: FreqsInterface
  }
  visible: { sectors: VisibleSectors; lines: VisibleLines; points: VisiblePoints; freq: VisibleFreqs }
  projection: GeoProjection
  zoom: number
  center: [number, number]
  isLabelDrag: boolean
}

const initialState: MapStateInterface = {
  geometry: {
    sectors: {} as SectorsInterface,
    lines: {} as LinesInterface,
    points: {} as PointsInterface,
    freq: {} as FreqsInterface,
  },
  visible: {
    sectors: { uklv: true, lve: false, lvw: false, lvt: false, ctr: false },
    lines: { ils13: false, ils31: false, rnav13: false, rnav31: false },
    points: { border: true },
    freq: { external: false },
  },
  zoom: 6000,
  center: [23, 50.7],
  projection: getProjection(6000, [23, 50.7]),
  isLabelDrag: false,
}

export const getMapDataThunk = createAsyncThunk<typeof initialState.geometry, void>('map/get_data', async () => {
  try {
    const response = await getMapDataRequest()
    return response.data
  } catch (e: any) {
    e.code = 0
    throw e
  }
})

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setZoom: (state, action) => {
      let newZoom: number = state.zoom + action.payload
      newZoom = newZoom < 1000 ? 1000 : newZoom
      state.zoom = newZoom
      state.projection = getProjection(newZoom, state.center)
    },
    setCenter: (state, action) => {
      const newCenter: [number, number] = [state.center[0] - action.payload[0], state.center[1] + action.payload[1]]
      state.center = newCenter
      state.projection = getProjection(state.zoom, newCenter)
    },
    setZoomAndCenter: (state, action) => {
      const newCenter: [number, number] = [state.center[0] - action.payload[0], state.center[1] + action.payload[1]]
      state.center = newCenter
      let newZoom: number = state.zoom + action.payload
      newZoom = newZoom < 1000 ? 1000 : newZoom
      state.zoom = newZoom
      state.projection = getProjection(newZoom, newCenter)
    },
    setLabelDrag: (state, action: PayloadAction<boolean>) => {
      state.isLabelDrag = action.payload
    },
    // TODO: think better of this
    toggleSector: (state, action: PayloadAction<keyof VisibleSectors>) => {
      ;(state.visible.sectors as VisibleSectors)[action.payload] = !(state.visible.sectors as VisibleSectors)[
        action.payload
      ]
    },
    toggleLine: (state, action: PayloadAction<keyof VisibleLines>) => {
      ;(state.visible.lines as VisibleLines)[action.payload] = !(state.visible.lines as VisibleLines)[action.payload]
    },
    togglePoints: (state, action: PayloadAction<keyof VisiblePoints>) => {
      ;(state.visible.points as VisiblePoints)[action.payload] = !(state.visible.points as VisiblePoints)[
        action.payload
      ]
    },
    toggleFreqs: (state, action: PayloadAction<keyof VisibleFreqs>) => {
      ;(state.visible.freq as VisibleFreqs)[action.payload] = !(state.visible.freq as VisibleFreqs)[action.payload]
    },
    // -----
  },
  extraReducers: builder => {
    builder.addCase(getMapDataThunk.fulfilled, (state, action) => {
      state.geometry = action.payload
    })
  },
})

const sliceSelector = (state: RootState) => state.map

export const selectProjection = createSelector(sliceSelector, state => state.projection)
export const selectSectorsData = createSelector(sliceSelector, state => state.geometry.sectors)
export const selectSectorsState = createSelector(sliceSelector, state => state.visible.sectors)
export const selectLinesData = createSelector(sliceSelector, state => state.geometry.lines)
export const selectLinesState = createSelector(sliceSelector, state => state.visible.lines)
export const selectPointsData = createSelector(sliceSelector, state => state.geometry.points)
export const selectPointsState = createSelector(sliceSelector, state => state.visible.points)
export const selectFreqData = createSelector(sliceSelector, state => state.geometry.freq)
export const selectFreqState = createSelector(sliceSelector, state => state.visible.freq)
export const selectIsLabelDrag = createSelector(sliceSelector, state => state.isLabelDrag)

export const {
  setZoom,
  setCenter,
  setZoomAndCenter,
  toggleSector,
  toggleLine,
  togglePoints,
  toggleFreqs,
  setLabelDrag,
} = mapSlice.actions

export default {
  name: mapSlice.name,
  reducer: mapSlice.reducer,
}
