import { FC, useCallback, useEffect } from 'react'

import LoginForm from 'components/LoginForm/LoginForm'
import Home from 'components/Home/Home'
import Loading from 'components/Theme/Loader/Loading'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectLoading, selectLoggedIn, validateTokenThunk } from 'store/authSlice'

import { getTokenData } from 'utils/storage'
import { getMapDataThunk } from 'store/mapSlice'

const HomePage: FC = () => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectLoggedIn)
  const loading = useAppSelector(selectLoading)

  const validate = useCallback(async () => {
    try {
      const token = getTokenData()
      if (token) {
        await dispatch(validateTokenThunk(token)).unwrap
        await dispatch(getMapDataThunk())
      }
    } catch (e) {
      console.log(e)
    }
  }, [dispatch])

  const getMap = useCallback(async () => {
    try {
      if (isLoggedIn) {
        await dispatch(getMapDataThunk())
      }
    } catch (e) {
      console.log(e)
    }
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    validate()
  }, [validate])

  useEffect(() => {
    // TODO: we can fetch saved config here
    getMap()
  }, [getMap])

  if (loading) {
    return <Loading />
  }

  return isLoggedIn ? <Home /> : <LoginForm />
}

export default HomePage
