import { FC } from 'react'
import useMapEvents from 'hooks/useMapEvents'

const EventsLayer: FC = () => {
  useMapEvents()

  return null
}

export default EventsLayer
