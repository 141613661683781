import { ChangeEvent, FC } from 'react'
import dayjs from 'dayjs'
import { Box, Button, FormControlLabel, Radio, RadioGroup, Slider, styled } from '@mui/material'

import WindowFrame from 'components/Windows/WindowFrame'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectTime, setTickSpeed, setTickValue, switchStart } from 'store/timeSlice'
import { toggleWindow } from 'store/windowsSlice'

const TimeContainer = styled(Box)`
  width: 300px;
  background-color: ${({ theme }) => theme.palette.grayscale.black};
  color: ${({ theme }) => theme.palette.grayscale.white};
  font-size: ${({ theme }) => theme.spacing(9)};
  text-align: center;
  vertical-align: middle;
`

const ControlsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
`

const SystemTimeWindow: FC = () => {
  const dispatch = useAppDispatch()
  const { startTime, currentTime, start, tickSpeed, tickValue, duration } = useAppSelector(selectTime)

  const handleClose = () => {
    dispatch(toggleWindow('systemTimeWindow'))
  }

  const handleStartClick = () => {
    dispatch(switchStart())
  }

  const handleSpeedChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(setTickSpeed(Number(value)))
  }

  const handleTickChange = (_: Event, value: number | number[]) => {
    dispatch(setTickValue(value))
  }

  return (
    <WindowFrame title="System Time" onClose={handleClose}>
      <TimeContainer>{dayjs(startTime + currentTime * 1000).format('HH:mm:ss')}</TimeContainer>
      <ControlsContainer>
        <Button onClick={handleStartClick}>{start ? 'Pause' : 'Start'}</Button>
        <RadioGroup row name="speed" value={tickSpeed} onChange={handleSpeedChange}>
          <FormControlLabel value={1000} control={<Radio />} label="x1" />
          <FormControlLabel value={500} control={<Radio />} label="x2" />
          <FormControlLabel value={250} control={<Radio />} label="x4" />
        </RadioGroup>
      </ControlsContainer>
      <Slider value={tickValue} min={0} max={duration / 1000 / 4} onChange={handleTickChange} />
      {/* max={duration / 1000 / 4} - 4 is tick size in seconds */}
    </WindowFrame>
  )
}

export default SystemTimeWindow
