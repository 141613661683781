export const xmlCoordToDecimalDegrees = (coordinate: string) =>
  Number.isNaN(parseFloat(coordinate))
    ? Number(parseInt(coordinate.substring(1, 4), 10)) +
      Number(parseInt(coordinate.substring(5, 7), 10) / 60) +
      Number(parseFloat(coordinate.substring(8)) / 3600)
    : parseFloat(coordinate)

export const degreesRadiansConverter = {
  toRadians: (d: number) => {
    return d * (Math.PI / 180)
  },
  toDegrees: (r: number) => {
    return r / (Math.PI / 180)
  },
  getDiffBetweenAngles(a: number, b: number) {
    const y = this.toRadians(b % 360)
    const x = this.toRadians(a % 360)
    return this.toDegrees(Math.acos(Math.cos(x) * Math.cos(y) + Math.sin(y) * Math.sin(x)))
  },
}
