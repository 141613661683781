import { FC, useState } from 'react'
import { Box, IconButton, styled, TextField } from '@mui/material'
import { useDebounce, useEffectOnce } from 'react-use'

import { useAppSelector } from 'store/store'

interface FiltersComponentProps {
  selector: (...args: any) => any
  setDebounced: (...args: any) => void
  filters: Record<string, number>
  delay?: number
}

const FiltersWrapper = styled(Box)`
  position: relative;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2.5)};
  padding-left: ${({ theme }) => theme.spacing(6.25)};
  padding-right: ${({ theme }) => theme.spacing(1.25)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  &:hover > .clear {
    visibility: visible;
  }
`

const ClearButton = styled(IconButton)`
  position: absolute;
  visibility: hidden;
  padding: 0;
  font-size: ${({ theme }) => theme.spacing(1.75)};
  width: ${({ theme }) => theme.spacing(3.75)};
  height: ${({ theme }) => theme.spacing(3.75)};
  top: ${({ theme }) => theme.spacing(0.25)};
  left: ${({ theme }) => theme.spacing(1)};
`

const FiltersComponent: FC<FiltersComponentProps> = ({ selector, filters, setDebounced, delay }) => {
  const objects: any = useAppSelector(selector)
  const [filtersState, setFiltersState] = useState<Record<keyof typeof filters, string>>({})

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target
    setFiltersState(prev => ({ ...prev, [name]: value }))
  }

  useDebounce(
    () => {
      setDebounced(
        objects.filter((object: any) => {
          const boolResults: boolean[] = []
          Object.keys(filters).forEach(key => {
            boolResults.push(
              filtersState[key]?.trim()
                ? object[key]?.toString().toLowerCase().includes(filtersState[key].toString().toLowerCase())
                : true,
            )
          })
          return boolResults.every(result => result)
        }),
      )
    },
    delay,
    [filtersState],
  )

  const clearFilters = () => {
    setFiltersState({})
  }

  useEffectOnce(() => {
    setDebounced(objects)
  })

  return (
    <FiltersWrapper>
      <ClearButton title="Clear filters" size="small" color="error" className="clear" onClick={clearFilters}>
        X
      </ClearButton>
      {Object.entries(filters).map(([name, width]) => (
        <TextField
          key={name}
          size="small"
          sx={{ width }}
          variant="standard"
          color="info"
          name={name}
          value={filtersState[name] || ''}
          onChange={handleFilterChange}
          autoComplete="off"
        />
      ))}
    </FiltersWrapper>
  )
}

FiltersComponent.defaultProps = {
  delay: 400,
}

export default FiltersComponent
