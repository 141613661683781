import { useCallback, useEffect, useRef } from 'react'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectTime, setCurrentTime, setTickValue, switchStart } from 'store/timeSlice'
import { selectPosition } from 'store/configSlice'

const useTime = () => {
  const dispatch = useAppDispatch()
  const { start, tickSpeed, tickValue, currentTime, duration } = useAppSelector(selectTime)
  const position = useAppSelector(selectPosition)
  const interval = useRef<NodeJS.Timer>()

  const intervalFunc = useCallback(() => {
    if (start) {
      // duration/1000 - duration in sec
      if (currentTime === duration / 1000) {
        dispatch(switchStart())
        if (interval.current) {
          clearInterval(interval.current)
        }
        return
      }
      const updatedTime = currentTime + 1

      dispatch(setCurrentTime(updatedTime))
      if (updatedTime % 4 === 3) {
        dispatch(setTickValue(tickValue + 1))
      }
    }
  }, [start, currentTime, duration, dispatch, tickValue])

  useEffect(() => {
    if (position !== 'con') {
      interval.current = setInterval(intervalFunc, tickSpeed)
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [tickSpeed, position, intervalFunc])
}

export default useTime
