import { FC } from 'react'

import MainMenu from 'components/Windows/MainMenu'
import ASWToolboxWindow from 'components/Windows/ASWToolboxWindow'
import SystemTimeWindow from 'components/Windows/SystemTimeWindow'
import SpeedVectorWindow from 'components/Windows/SpeedVectorWindow'
import HistoricalWindow from 'components/Windows/HistoricalWindow'
import RunwaysWindow from 'components/Windows/RunwaysWindow'
import SectorsWindow from 'components/Windows/SectorsWindow'

import { useAppSelector } from 'store/store'
import { selectWindows } from 'store/windowsSlice'

const WindowLayer: FC = () => {
  // TODO: create selector for this - not all windows are really needed
  const {
    mainMenu,
    aswToolbox1Window,
    systemTimeWindow,
    speedVectorWindow,
    historicalWindow,
    rwyWindow,
    sectorWindow,
  } = useAppSelector(selectWindows)

  return (
    <>
      {mainMenu && <MainMenu />}
      {aswToolbox1Window && <ASWToolboxWindow />}
      {systemTimeWindow && <SystemTimeWindow />}
      {speedVectorWindow && <SpeedVectorWindow />}
      {historicalWindow && <HistoricalWindow />}
      {rwyWindow && <RunwaysWindow />}
      {sectorWindow && <SectorsWindow />}
    </>
  )
}

export default WindowLayer
