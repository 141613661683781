import { FC } from 'react'
import { NestedMenuItem } from 'mui-nested-menu'
import { Button } from '@mui/material'

import MenuDir from 'components/Windows/components/MenuDir'
import useMenu from 'hooks/useMenu'

const MapMenu: FC = () => {
  const { openMenu, isMenuOpen, Menu } = useMenu()

  return (
    <>
      <Button onClick={openMenu}>Map</Button>
      <Menu>
        <NestedMenuItem parentMenuOpen={isMenuOpen} label="Sectors" className="no-drag">
          <MenuDir section="sectors" />
        </NestedMenuItem>
        <NestedMenuItem parentMenuOpen={isMenuOpen} label="Stars">
          <MenuDir section="lines" />
        </NestedMenuItem>
        <NestedMenuItem parentMenuOpen={isMenuOpen} label="Points">
          <MenuDir section="points" />
        </NestedMenuItem>
        <NestedMenuItem parentMenuOpen={isMenuOpen} label="Freq">
          <MenuDir section="freq" />
        </NestedMenuItem>
      </Menu>
    </>
  )
}

export default MapMenu
