import axios from 'axios'

export const AuthService = axios.create({
  baseURL: 'https://lvivacc.site/wp-json/jwt-auth/v1/token',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const ApiService = axios.create({
  baseURL: 'https://lvivacc.site/ptt/exercise_2.php',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
})
