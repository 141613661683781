import { FC, useEffect, useMemo } from 'react'
import { GeoJsonProperties } from 'geojson'
import { select, Selection } from 'd3-selection'
import { GeoPermissibleObjects } from 'd3-geo'
import { useTheme } from '@mui/material'

import { useAppSelector } from 'store/store'
import { selectLinesState, selectSectorsState } from 'store/mapSlice'
import { selectFilled } from 'store/configSlice'

interface LineMapObjectPropsInterface {
  drawFunction: <T extends GeoPermissibleObjects & GeoJsonProperties>(
    svg: Selection<any, any, any, any>,
    datum: T,
  ) => void
  data: ExtendedFeature
  isLine?: boolean
}

const LineMapObject: FC<LineMapObjectPropsInterface> = ({ drawFunction, data, isLine }) => {
  const selector = isLine ? selectLinesState : selectSectorsState
  const objects = useAppSelector<VisibleLines | VisibleSectors>(selector)
  const filled = useAppSelector(selectFilled)
  const theme = useTheme()

  const isFilled = filled && data.properties.sectorGroup === 'lvt'

  const styles: Record<LineObjectsVariants, Record<string, string>> = useMemo(
    () => ({
      sector: {
        stroke: theme.palette.sector.border,
        fill: isFilled ? theme.palette.sector.fill : 'none',
      },
      star: {
        stroke: theme.palette.secondary.dark,
        fill: 'none',
      },
      ils: { stroke: theme.palette.grayscale.black, fill: 'none' },
    }),
    [theme, isFilled],
  )

  useEffect(() => {
    const svg = select(`#${data.id}`)
    drawFunction<ExtendedFeature>(svg, data)
    Object.entries(styles[data.properties.lineGroup]).forEach(([key, value]) => {
      svg.style(key, value)
    })
  }, [drawFunction, data, styles])

  const isVisible = objects[data.id as keyof typeof objects]

  return <g id={data.id as string} visibility={isVisible ? 'visible' : 'hidden'} />
}

LineMapObject.defaultProps = {
  isLine: false,
}

export default LineMapObject
