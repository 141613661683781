import { FC, RefObject, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material'
import Draggable, { DraggableEventHandler } from 'react-draggable'
import { GeoProjection } from 'd3-geo'

import LabelRow from 'components/Label/LabelRow'
import LabelItem from 'components/Label/LabelItem'
import { LabelBorder } from 'components/Label/LabelBorder'
import { getEndCoords, LabelLine } from 'components/Label/LabelLine'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectLabelFontSize, selectLabelLines } from 'store/configSlice'
import { setLabelDrag } from 'store/mapSlice'

import { geoToScreenCoordinates } from 'utils/map'
import { getAltitudeValue, getHeadingValue, getRateText } from 'helpers/helpers'
import { defaultLabelOffset } from 'constants/constants'
import { selectEppSelected } from 'store/exerciseSlice'

export const LabelComponent = styled('g')`
  & > rect {
    stroke: ${({ theme }) => theme.palette.grayscale.white};
  }

  &:hover {
    & > g {
      visibility: visible;
    }
  }
`

const Label: FC<{ position: TrackTickPositionInterface; projection: GeoProjection; acType: string; id: string }> = ({
  position,
  projection,
  acType,
  id,
}) => {
  const dispatch = useAppDispatch()
  const labelFont = useAppSelector(selectLabelFontSize)
  const { three, four } = useAppSelector(selectLabelLines)
  const selectedEpp = useAppSelector(selectEppSelected)

  const thisSelected = selectedEpp === id

  const { cP, a, sA, xfl, c, gs, nPN, h, sH, s, sS, r, isClimb, isDesc } = position

  const [xCoord, yCoord]: number[] = geoToScreenCoordinates(cP, projection)
  const delta = useRef<{ x: number; y: number }>(defaultLabelOffset)
  const [labelPosition, setLabelPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const labelRef = useRef<SVGGElement>(null)

  useEffect(() => {
    setLabelPosition({
      x: xCoord + delta.current.x,
      y: yCoord + delta.current.y,
    })
  }, [projection, xCoord, yCoord])

  const onDrag: DraggableEventHandler = (e, data) => {
    const { x, y, lastX, lastY } = data
    setLabelPosition({ x, y })
    delta.current = { x: lastX - xCoord, y: lastY - yCoord }
  }

  // eslint-disable-next-line consistent-return
  const onStart: DraggableEventHandler = e => {
    if ((e as MouseEvent).button === 2) {
      dispatch(setLabelDrag(true))
    } else {
      return false
    }
  }

  const onStop: DraggableEventHandler = () => {
    dispatch(setLabelDrag(false))
  }

  const hasAlarms = false
  const showSelectedHeading = sH && h.toFixed() !== sH.toFixed()
  const showSelectedSpeed = sS && s.toFixed() !== sS.toFixed()

  const [bounds, setBounds] = useState({ w: 0, h: 0 })
  const lineTwoRef = useRef(null)
  const lineThreeRef = useRef(null)
  const lineFourRef = useRef(null)

  const [labelHover, setLabelHover] = useState(false)
  useEffect(() => {
    if (lineThreeRef.current && lineFourRef.current && lineTwoRef.current) {
      const lineTwoW = (lineTwoRef.current as HTMLElement).getBoundingClientRect().width
      const lineThreeW = (lineThreeRef.current as HTMLElement).getBoundingClientRect().width
      const lineFourW = (lineFourRef.current as HTMLElement).getBoundingClientRect().width

      let heightMultiplier = hasAlarms ? 3 : 2
      if (labelHover) {
        heightMultiplier += 2
      } else {
        if (three && !four) {
          heightMultiplier += 1
        }
        if (four || thisSelected) {
          heightMultiplier += 2
        }
      }

      let maxWidth = lineTwoW + 18
      if (labelHover || three || four || thisSelected) {
        maxWidth = (lineThreeW > lineFourW ? lineThreeW : lineFourW) + 4
      }

      setBounds({
        w: maxWidth,
        h: heightMultiplier * labelFont + 8,
      })
    }
  }, [four, hasAlarms, labelFont, labelHover, showSelectedHeading, showSelectedSpeed, three, thisSelected])

  const onFieldClick = (fieldName: string) => () => {
    console.log(fieldName)
  }

  const handleLabelHover = (value: boolean) => () => {
    setLabelHover(value)
  }

  return (
    <>
      <LabelLine
        x1={xCoord}
        y1={yCoord}
        {...getEndCoords([xCoord, yCoord], [labelPosition.x, labelPosition.y], bounds)}
      />
      <Draggable
        allowAnyClick
        nodeRef={labelRef as unknown as RefObject<HTMLElement>}
        position={labelPosition}
        onDrag={onDrag}
        onStart={onStart}
        onStop={onStop}
        bounds={{
          left: xCoord - 200,
          top: yCoord - 200,
          right: xCoord + 200,
          bottom: yCoord + 200,
        }}
      >
        <LabelComponent ref={labelRef} onMouseEnter={handleLabelHover(true)} onMouseLeave={handleLabelHover(false)}>
          {(labelHover || thisSelected) && <LabelBorder x={0} y={0} width={bounds.w} height={bounds.h} />}
          <LabelRow rowNumber={1} />
          <LabelRow rowNumber={hasAlarms ? 2 : 1}>
            <LabelItem onClick={onFieldClick('c/s')} offset={0}>
              {c}
            </LabelItem>
          </LabelRow>
          <LabelRow rowNumber={hasAlarms ? 3 : 2} ref={lineTwoRef}>
            <LabelItem onClick={onFieldClick('alt')} offset={0}>
              {getAltitudeValue(a)}
              {isClimb && '\u2191'}
              {isDesc && '\u2193'}
            </LabelItem>
            <LabelItem offset={36} onClick={onFieldClick('cfl')}>
              {getAltitudeValue(sA)}
            </LabelItem>
            <LabelItem onClick={onFieldClick('xfl')} offset={72}>
              {xfl}
            </LabelItem>
          </LabelRow>
          <LabelRow rowNumber={hasAlarms ? 4 : 3} isThree={three || thisSelected} ref={lineThreeRef}>
            <LabelItem onClick={onFieldClick('gs')} offset={0}>
              {gs?.toFixed()}
            </LabelItem>
            <LabelItem onClick={onFieldClick('point')} offset={36}>
              {nPN}
            </LabelItem>
            <LabelItem onClick={onFieldClick('point')} offset={100}>
              {acType}
            </LabelItem>
          </LabelRow>
          <LabelRow rowNumber={hasAlarms ? 5 : 4} isFour={four || thisSelected} ref={lineFourRef}>
            <LabelItem onClick={onFieldClick('hdg')} offset={0}>
              h{getHeadingValue(h)}
              {showSelectedHeading ? `>${getHeadingValue(sH)} ` : ' '}
            </LabelItem>
            <LabelItem onClick={onFieldClick('spd')} offset={showSelectedHeading ? 80 : 40}>
              s{s.toFixed()}
              {showSelectedSpeed ? `>${sS.toFixed()} ` : ' '}
            </LabelItem>
            <LabelItem
              onClick={onFieldClick('rate')}
              offset={showSelectedHeading ? (showSelectedSpeed ? 160 : 120) : showSelectedSpeed ? 120 : 80}
            >
              r{isClimb && '+'}
              {isDesc && '-'}
              {r.toFixed() !== '0' && getRateText(r)}
            </LabelItem>
          </LabelRow>
        </LabelComponent>
      </Draggable>
    </>
  )
}

export default Label
