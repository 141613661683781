import { FC, useEffect } from 'react'
import { select, Selection } from 'd3-selection'
import { useTheme } from '@mui/material'

import { useAppSelector } from 'store/store'
import { selectFreqState, selectPointsState } from 'store/mapSlice'

interface PointMapObjectPropsInterface {
  drawFunction: (svg: Selection<any, any, any, any>, data: MapPointInterface[], isFreq?: boolean) => void
  data: MapPointInterface[]
  id: string
  isFreq?: boolean
}

const PointMapObject: FC<PointMapObjectPropsInterface> = ({ drawFunction, data, id, isFreq }) => {
  const selector = isFreq ? selectFreqState : selectPointsState
  const objects = useAppSelector<VisibleFreqs | VisiblePoints>(selector)
  const theme = useTheme()

  useEffect(() => {
    const svg = select(`#${id}`)
    drawFunction(svg, data, isFreq)
    svg.style('fill', isFreq ? theme.palette.secondary.light : theme.palette.primary.contrastText)
  }, [drawFunction, data, id, isFreq, theme])

  const isVisible = objects[id as keyof typeof objects]

  return <g id={id} visibility={isVisible ? 'visible' : 'hidden'} />
}

PointMapObject.defaultProps = {
  isFreq: false,
}

export default PointMapObject
