export const persistTokenData = (data: LoginResponseDataInterface) => {
  Object.entries(data).forEach(([key, value]) => {
    localStorage.setItem(key, value)
  })
}

export const getTokenData = (): string | null => localStorage.getItem('token')

export const getUserData = (): { atm_code: string; user_display_name: string } => {
  const userDisplayName = localStorage.getItem('user_display_name')
  const atmCode = localStorage.getItem('atm_code')
  return { atm_code: atmCode!, user_display_name: userDisplayName! }
}

export const removeTokenData = () => {
  return localStorage.clear()
}
