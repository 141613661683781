import { FC, MouseEvent } from 'react'
import { Button, ToggleButton } from '@mui/material'

import MenuWrapper from 'components/Windows/MenuWrapper'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectMainMenuWindows, toggleWindow } from 'store/windowsSlice'
import { clearRoutes } from 'store/exerciseSlice'

const MainMenu: FC = () => {
  const dispatch = useAppDispatch()
  const { rwyWindow, mtcdWindow } = useAppSelector(selectMainMenuWindows)

  const handleClick = (e: MouseEvent, value: string) => {
    dispatch(toggleWindow(value))
  }

  const handleClearRTE = () => {
    dispatch(clearRoutes())
  }

  return (
    <MenuWrapper isBottom>
      <ToggleButton value="rwyWindow" selected={rwyWindow} onClick={handleClick}>
        Runway
      </ToggleButton>
      <ToggleButton value="mtcdWindow" selected={mtcdWindow} onClick={handleClick}>
        MTCD
      </ToggleButton>
      <Button onClick={handleClearRTE}>RTE off</Button>
    </MenuWrapper>
  )
}

export default MainMenu
