import { FC, ReactNode } from 'react'
import { AppBar, Stack, useTheme } from '@mui/material'

const MenuWrapper: FC<{ children: ReactNode; isBottom?: boolean }> = ({ children, isBottom }) => {
  const theme = useTheme()

  return (
    <AppBar
      sx={{ padding: theme.spacing(0.5), top: isBottom ? 'auto' : 0, bottom: isBottom ? 0 : 'auto' }}
      component="div"
    >
      <Stack direction="row" gap={theme.spacing(0.5)}>
        {children}
      </Stack>
    </AppBar>
  )
}

MenuWrapper.defaultProps = {
  isBottom: false,
}

export default MenuWrapper
