import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef } from 'react'
import { GeoProjection } from 'd3-geo'

import Route from 'components/Route/Route'

import { useAppDispatch, useAppSelector } from 'store/store'
import {
  addRoute,
  deSelectTrackInEpp,
  removeRoute,
  selectEppSelected,
  selectRoutes,
  selectTrackInEpp,
  updateTimeShift,
} from 'store/exerciseSlice'

import { geoToScreenCoordinates } from 'utils/map'

const Mark: FC<{
  id: string
  position: TrackTickPositionInterface
  projection: GeoProjection
  route: TrackRoutePoint[]
}> = ({ id, position, projection, route }) => {
  const dispatch = useAppDispatch()
  const routes = useAppSelector(selectRoutes)
  const selectedFPL = useAppSelector(selectEppSelected)

  const thisSelected = useMemo(() => selectedFPL === id, [selectedFPL, id])

  const { cP, nP } = position

  const [x, y]: number[] = geoToScreenCoordinates(cP, projection)
  const showRoute = routes.includes(id)

  const clickHoldTimer = useRef<NodeJS.Timer>()

  const handleMouseDown: MouseEventHandler<SVGRectElement> = e => {
    if (e.buttons === 1)
      clickHoldTimer.current = setTimeout(() => {
        if (thisSelected) {
          dispatch(deSelectTrackInEpp())
        } else {
          dispatch(selectTrackInEpp(id))
        }
      }, 1000)

    if (e.buttons === 2) {
      dispatch(showRoute ? removeRoute(id) : addRoute(id))
    }
  }

  const handleMouseUp: MouseEventHandler<SVGRectElement> = () => {
    if (clickHoldTimer.current) {
      clearTimeout(clickHoldTimer.current)
    }
  }

  const handleTimeShiftChange = useCallback(
    (e: KeyboardEvent) => {
      if (thisSelected) {
        switch (e.key) {
          case 'ArrowUp':
            dispatch(updateTimeShift(5))
            break
          case 'ArrowDown':
            dispatch(updateTimeShift(-5))
            break
          default:
        }
      }
    },
    [dispatch, thisSelected],
  )

  useEffect(() => {
    document.body.addEventListener('keydown', handleTimeShiftChange)

    return () => {
      document.body.removeEventListener('keydown', handleTimeShiftChange)
    }
  }, [handleTimeShiftChange])

  return (
    <>
      <g>
        <use href="#target" x={x - 4} y={y - 4} stroke="blue" />
        <rect
          x={x - 4}
          y={y - 4}
          width={8}
          height={8}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          fill="transparent"
        />
      </g>
      {showRoute && <Route current={[x, y]} nextPointIndex={nP} route={route} projection={projection} />}
    </>
  )
}

export default Mark
