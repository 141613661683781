import * as geolib from 'geolib'
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

import {
  getAirportsRequest,
  getConstraintsRequest,
  getCopsRequest,
  getPerformanceRequest,
  getSectorsRequest,
} from 'services/navDataService'

interface NavDataStateInterface {
  airports: Record<string, AirportInterface>
  cops: string[]
  basicSectors: BasicSectorInterface[]
  opsSectors: OpsSectorInterface[]
  performance: Record<string, PerformanceInterface>
  constraints: ConstraintInterface[]
}

const initialState: NavDataStateInterface = {
  airports: {},
  cops: [],
  basicSectors: [],
  opsSectors: [],
  performance: {},
  constraints: [],
}

export const getAirportsThunk = createAsyncThunk<Record<string, AirportInterface>, void>(
  'navData/get_airports',
  async () => {
    try {
      const response = await getAirportsRequest()
      const airports: Record<string, AirportInterface> = response.data

      Object.keys(airports).forEach(airport => {
        const currentAirportRunways = airports[airport].Runways

        Object.keys(currentAirportRunways).forEach(runway => {
          const currentRunway = airports[airport].Runways[runway]
          const deviation = 6
          const sectorAngle = 5
          currentRunway.approachArea = [
            currentRunway.threshold,
            geolib.computeDestinationPoint(
              currentRunway.threshold,
              46 * 1000 * 1.852,
              (currentRunway.heading + 180 + deviation + sectorAngle) % 360,
            ),
            geolib.computeDestinationPoint(
              currentRunway.threshold,
              46 * 1000 * 1.852,
              (currentRunway.heading + 180 + deviation - sectorAngle) % 360,
            ),
            currentRunway.threshold,
          ]
        })
      })

      return airports
    } catch (e: any) {
      e.code = 0
      throw e
    }
  },
)

export const getCopsThunk = createAsyncThunk<string[], void>('navData/get_cops', async () => {
  try {
    const response = await getCopsRequest()

    return response.data.cops
  } catch (e: any) {
    e.code = 0
    throw e
  }
})

export const getPerformanceThunk = createAsyncThunk<Record<string, PerformanceInterface>, void>(
  'navData/get_performance',
  async () => {
    try {
      const response = await getPerformanceRequest()

      return response.data
    } catch (e: any) {
      e.code = 0
      throw e
    }
  },
)

export const getConstraintsThunk = createAsyncThunk<ConstraintInterface[], void>(
  'navData/get_constraints',
  async () => {
    try {
      const response = await getConstraintsRequest()
      return response.data
    } catch (e: any) {
      e.code = 0
      throw e
    }
  },
)

export const getSectorsThunk = createAsyncThunk<
  { basicSectors: BasicSectorInterface[]; opsSectors: OpsSectorInterface[] },
  void
>('navData/get_sectors', async () => {
  try {
    const response = await getSectorsRequest()

    return response.data
  } catch (e: any) {
    e.code = 0
    throw e
  }
})

const navDataSlice = createSlice({
  name: 'navData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAirportsThunk.fulfilled, (state, action) => {
      state.airports = action.payload
    })
    builder.addCase(getCopsThunk.fulfilled, (state, action) => {
      state.cops = action.payload
    })
    builder.addCase(getPerformanceThunk.fulfilled, (state, action) => {
      state.performance = action.payload
    })
    builder.addCase(getConstraintsThunk.fulfilled, (state, action) => {
      state.constraints = action.payload
    })
    builder.addCase(
      getSectorsThunk.fulfilled,
      (state, action: PayloadAction<{ basicSectors: BasicSectorInterface[]; opsSectors: OpsSectorInterface[] }>) => {
        state.basicSectors = action.payload.basicSectors
        state.opsSectors = action.payload.opsSectors
      },
    )
  },
})

const sliceSelector = (state: RootState) => state.navData

export const selectAirports = createSelector(sliceSelector, state => state.airports)
export const selectCops = createSelector(sliceSelector, state => state.cops)
export const selectBasicSectors = createSelector(sliceSelector, state => state.basicSectors)
export const selectOpsSectors = createSelector(sliceSelector, state => state.opsSectors)
export const selectPerformance = createSelector(sliceSelector, state => state.performance)
export const selectConstraints = createSelector(sliceSelector, state => state.constraints)

// export const { } = navDataSlice.actions

export default {
  name: navDataSlice.name,
  reducer: navDataSlice.reducer,
}
