import { AnyAction, AsyncThunk } from '@reduxjs/toolkit'

type TGenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type TPendingAction = ReturnType<TGenericAsyncThunk['pending']>
type TRejectedAction = ReturnType<TGenericAsyncThunk['rejected']>
type TFulfilledAction = ReturnType<TGenericAsyncThunk['fulfilled']>

export function isPendingAction(action: AnyAction): action is TPendingAction {
  return action.type.endsWith('/pending')
}

export function isFulfilledAction(action: AnyAction): action is TFulfilledAction {
  return action.type.endsWith('/fulfilled')
}

export function isRejectedAction(action: AnyAction): action is TRejectedAction {
  return action.type.endsWith('/rejected')
}
