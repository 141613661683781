import { FC } from 'react'

import useTime from 'hooks/useTime'

const TimeLayer: FC = () => {
  useTime()

  return null
}

export default TimeLayer
