import { FC, useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Paper, Typography, useTheme } from '@mui/material'
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColumns, GridRowParams, GridSelectionModel } from '@mui/x-data-grid'

import WindowFrame from 'components/Windows/WindowFrame'
import FiltersComponent from 'components/Windows/components/Filters'
import WindowTable from 'components/Windows/components/Table'

import { useAppDispatch, useAppSelector } from 'store/store'
import { toggleWindow } from 'store/windowsSlice'
import { addExerciseFPLs, selectExerciseFPLs, selectHistoricalFPLs } from 'store/exerciseSlice'

import useNavCalculations from 'hooks/useNavCalculations'

const historicalFilters: Record<string, number> = {
  c: 94,
  type: 68,
  adep: 68,
  ades: 68,
  entryCop: 76,
  exitCop: 76,
  rfl: 60,
}

const columns: GridColumns = [
  { ...GRID_CHECKBOX_SELECTION_COL_DEF, disableColumnMenu: true },
  { field: 'c', headerName: 'Callsign', disableColumnMenu: false, width: 114 },
  { field: 'type', headerName: 'TYPE', disableColumnMenu: true, width: 88 },
  { field: 'adep', headerName: 'ADEP', disableColumnMenu: true, width: 88 },
  { field: 'ades', headerName: 'ADES', disableColumnMenu: true, width: 88 },
  { field: 'entryCop', headerName: 'ENTRY', disableColumnMenu: true, width: 96 },
  { field: 'exitCop', headerName: 'EXIT', disableColumnMenu: true, width: 96 },
  { field: 'rfl', headerName: 'RFL', disableColumnMenu: true, width: 80 },
]

const HistoricalWindow: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const exerciseFPLs = useAppSelector(selectExerciseFPLs)

  const [rows, setRows] = useState<HistoryInterface[]>([])

  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([])
  const onSelect = (newSelectionModel: GridSelectionModel) => {
    setSelectedRows(newSelectionModel)
  }

  const handleClose = () => {
    dispatch(toggleWindow('historicalWindow'))
  }

  const { createFlight } = useNavCalculations()

  const handleAdd = () => {
    dispatch(addExerciseFPLs(createFlight(selectedRows as string[])))
    setSelectedRows([])
  }

  const gridRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const allRows = gridRef.current?.querySelectorAll('.MuiDataGrid-row')
    allRows?.forEach(row => {
      const disabled = row.querySelector('.Mui-disabled')
      if (disabled) {
        row.classList.add('disabled')
      }
    })
  })

  return (
    <>
      <WindowFrame title="Historical FPLs" onClose={handleClose} zIndex={theme.zIndex.drawer} maxHeight={600}>
        <Box sx={{ flexGrow: 1 }} width={700}>
          <FiltersComponent filters={historicalFilters} selector={selectHistoricalFPLs} setDebounced={setRows} />
          <WindowTable
            // for all tables
            autoHeight
            pageSize={10}
            rowsPerPageOptions={[10]}
            density="compact"
            hideFooterSelectedRowCount
            // ---------------
            ref={gridRef}
            checkboxSelection
            columns={columns}
            rows={rows}
            isRowSelectable={(params: GridRowParams) => !exerciseFPLs.map(fpl => fpl.id).includes(params.row.id)}
            componentsProps={{ baseCheckbox: { color: 'info' } }}
            onSelectionModelChange={onSelect}
            selectionModel={selectedRows}
          />
        </Box>
      </WindowFrame>
      {selectedRows.length > 0 && (
        <Paper
          sx={{ width: '100%', position: 'fixed', left: 0, bottom: 0, p: 3, zIndex: theme.zIndex.drawer }}
          elevation={4}
        >
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container direction="row" gap={2} alignItems="center">
                <Grid item>
                  <Typography>{selectedRows.length} selected</Typography>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      setSelectedRows([])
                    }}
                  >
                    Deselect
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" gap={2} alignItems="center">
                <Grid item>
                  <Button onClick={handleAdd}>Add</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  )
}

export default HistoricalWindow
