import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Stack, styled, useTheme } from '@mui/material'

import Header from 'components/Theme/Header/Header'

import { positions } from 'constants/constants'

const HomeWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Home: FC = () => {
  const theme = useTheme()

  return (
    <>
      <Header />
      <HomeWrapper>
        <Stack direction="column" gap={theme.spacing(3)} maxWidth="25%">
          {Object.entries(positions).map(([position, title]) => (
            <Button component={RouterLink} to={`/${position}`} key={position} fullWidth size="large">
              {title}
            </Button>
          ))}
        </Stack>
      </HomeWrapper>
    </>
  )
}

export default Home
