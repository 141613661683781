import { useCallback, useEffect } from 'react'

import { selectIsLabelDrag, setCenter, setZoom } from 'store/mapSlice'
import { useAppDispatch, useAppSelector } from 'store/store'

const useMapEvents = () => {
  const dispatch = useAppDispatch()
  const isLabelDrag = useAppSelector(selectIsLabelDrag)

  const mouseMoveOnMap = useCallback(
    (event: MouseEvent) => {
      switch (event.buttons) {
        case 2:
          if (!isLabelDrag) {
            dispatch(setZoom(event.movementY * 30))
          }
          break
        case 4:
          dispatch(setCenter([event.movementX / 100, event.movementY / 100]))
          break
        default:
          break
      }
    },
    [dispatch, isLabelDrag],
  )

  const onChangeZoom = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'PageDown':
          dispatch(setZoom(-100))
          break
        case 'PageUp':
          dispatch(setZoom(100))
          break
        default:
          break
      }
    },
    [dispatch],
  )

  useEffect(() => {
    document.body.addEventListener('mousemove', mouseMoveOnMap)
    document.body.addEventListener('keydown', onChangeZoom)
    return () => {
      document.body.removeEventListener('mousemove', mouseMoveOnMap)
      document.body.removeEventListener('keydown', onChangeZoom)
    }
  }, [mouseMoveOnMap, onChangeZoom])
}

export default useMapEvents
