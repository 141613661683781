import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import navDataSlice from 'store/navDataSlice'
import authSlice from './authSlice'
import configSlice from './configSlice'
import timeSlice from './timeSlice'
import windowsSlice from './windowsSlice'
import mapSlice from './mapSlice'
import exerciseSlice from './exerciseSlice'

const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [configSlice.name]: configSlice.reducer,
    [timeSlice.name]: timeSlice.reducer,
    [windowsSlice.name]: windowsSlice.reducer,
    [mapSlice.name]: mapSlice.reducer,
    [exerciseSlice.name]: exerciseSlice.reducer,
    [navDataSlice.name]: navDataSlice.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
