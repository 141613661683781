import { FC, MouseEvent } from 'react'
import { Button, Stack, ToggleButton, useTheme } from '@mui/material'

import WindowFrame from 'components/Windows/WindowFrame'
import MapMenu from 'components/Windows/MapMenu'
import ConfigMenu from 'components/Windows/ConfigMenu'
import TrailMenu from 'components/Windows/components/TrailMenu'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectToolboxWindows, toggleWindow } from 'store/windowsSlice'

const ASWToolboxWindow: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { speedVectorWindow } = useAppSelector(selectToolboxWindows)

  const handleToggle = (_: MouseEvent<HTMLElement>, value: string) => {
    dispatch(toggleWindow(value))
  }

  const handleClose = () => {
    dispatch(toggleWindow('aswToolbox1Window'))
  }

  return (
    <WindowFrame title="ASW Toolbox" onClose={handleClose} zIndex={theme.zIndex.drawer}>
      <Stack direction="row" gap={theme.spacing(0.5)} p={theme.spacing(1)}>
        <TrailMenu />
        <Button disabled>R&B Track</Button>
        <ConfigMenu />
        <MapMenu />
        <ToggleButton value="speedVectorWindow" selected={speedVectorWindow} onClick={handleToggle}>
          Vector
        </ToggleButton>
      </Stack>
    </WindowFrame>
  )
}

export default ASWToolboxWindow
