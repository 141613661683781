import { FC } from 'react'
import { Button, MenuItem, useTheme } from '@mui/material'

import useMenu from 'hooks/useMenu'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectHistoryValue, setHistoryValue } from 'store/configSlice'

const TrailMenu: FC = () => {
  const dispatch = useAppDispatch()
  const trailSize = useAppSelector(selectHistoryValue)

  const theme = useTheme()
  const { Menu, openMenu } = useMenu()

  const handleValueChange = (value: number) => () => {
    dispatch(setHistoryValue(value))
  }

  return (
    <>
      <Button onClick={openMenu}>Trail 10</Button>
      <Menu>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => (
          <MenuItem
            key={value}
            onClick={handleValueChange(value)}
            sx={{ backgroundColor: trailSize === value ? theme.palette.primary.dark : 'initial' }}
          >
            {value === 0 ? 'Off' : value}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default TrailMenu
