import { FC, MouseEvent } from 'react'
import dayjs from 'dayjs'
import { ToggleButton } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectTime } from 'store/timeSlice'
import { selectSystemTimeWindow, toggleWindow } from 'store/windowsSlice'

const ClockButton: FC = () => {
  const dispatch = useAppDispatch()
  const { startTime, currentTime } = useAppSelector(selectTime)
  const systemTimeWindow = useAppSelector(selectSystemTimeWindow)

  const handleToggle = (_: MouseEvent<HTMLElement>, value: string) => {
    dispatch(toggleWindow(value))
  }

  return (
    <ToggleButton value="systemTimeWindow" selected={systemTimeWindow} onClick={handleToggle}>
      {dayjs(startTime + currentTime * 1000).format('HH:mm:ss')}
    </ToggleButton>
  )
}

export default ClockButton
