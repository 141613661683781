import { FC } from 'react'
import { AppBar, Button, Toolbar, Typography } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'store/store'
import { logoutThunk, selectUserData } from 'store/authSlice'

const Header: FC = () => {
  const dispatch = useAppDispatch()
  const { user_display_name: userName } = useAppSelector(selectUserData)

  const handleLogout = () => {
    dispatch(logoutThunk())
  }

  return (
    <AppBar>
      <Toolbar>
        <Typography variant="body1">Hello, {userName}</Typography>
        <Button size="small" variant="text" onClick={handleLogout} sx={{ ml: 'auto' }}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default Header
