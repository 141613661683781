import { FC, MouseEvent, ReactNode, useState } from 'react'
import { Menu, useTheme } from '@mui/material'

const useMenu = (noDrag = true) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const MenuComponent: FC<{ children: ReactNode }> = ({ children }) => {
    return (
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchorEl}
        sx={{ marginTop: theme.spacing(1) }}
        className={noDrag ? 'no-drag' : ''}
      >
        {children}
      </Menu>
    )
  }

  return { Menu: MenuComponent, openMenu, closeMenu, isMenuOpen: open }
}

export default useMenu
