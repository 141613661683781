import { geoMercator, GeoProjection } from 'd3-geo'
import { xmlCoordToDecimalDegrees } from 'utils/converters'

export const getProjection = (zoom: number, lonLatCenter: [number, number]): GeoProjection =>
  geoMercator().scale(zoom).center(lonLatCenter)

export const getSvgPointCoordinate = (
  d: PointInterface,
  projection: GeoProjection,
  isText = false,
): [number, number] => {
  const point = projection([
    xmlCoordToDecimalDegrees(d.longitude as string),
    xmlCoordToDecimalDegrees(d.latitude as string),
  ])
  if (point) {
    point[0] -= isText ? -4 : 4
    point[1] -= isText ? 0 : 4
  }
  return point ?? [0, 0]
}

export const geoToScreenCoordinates = (d: PointInterface, projection: GeoProjection): [number, number] =>
  projection([d.longitude as number, d.latitude as number]) ?? [0, 0]
