import { FC, Fragment, useRef } from 'react'
import * as geolib from 'geolib'
import { GeoProjection } from 'd3-geo'

import { useAppSelector } from 'store/store'
import { selectVector } from 'store/configSlice'

import { geoToScreenCoordinates } from 'utils/map'

// @ts-ignore
const SpeedVector: FC<{ track: TrackInterface; projection: GeoProjection; tick: number }> = ({
  track: { tickPositions, cas },
  projection,
  tick,
}) => {
  const { vectorValue, isVectorShown, isVectorMarksShown } = useAppSelector(selectVector)

  const trackPosition = tickPositions[tick].cP
  const segmentHeading = tickPositions[tick].h + 6
  const segmentLength = (((tickPositions[tick].gs || cas) * 1.852) / 60) * 1000

  const segmentEndCoords = useRef<PointInterface[]>([])

  const calcDestinationCoordinates = (position: PointInterface) =>
    geolib.computeDestinationPoint(position, segmentLength, segmentHeading)

  return isVectorShown
    ? new Array(vectorValue)
        .fill(null)
        .map((_, i) => i)
        .map(position => {
          if (position === 0) {
            const endGeo = calcDestinationCoordinates(trackPosition)
            const start = geoToScreenCoordinates(trackPosition, projection)
            const end = geoToScreenCoordinates(endGeo, projection)
            segmentEndCoords.current[position] = endGeo

            return { start, end }
          }

          const startGeo = segmentEndCoords.current[position - 1]
          const endGeo = calcDestinationCoordinates(startGeo)
          const start = geoToScreenCoordinates(startGeo, projection)
          const end = geoToScreenCoordinates(endGeo, projection)
          segmentEndCoords.current[position] = endGeo

          return { start, end }
        })
        .map(({ start, end }) => (
          <Fragment key={`${start[0]}-${end[0]}-${Math.random()}`}>
            <line x1={start[0]} y1={start[1]} x2={end[0]} y2={end[1]} stroke="#72b4ff" />
            {isVectorMarksShown && <circle cx={end[0]} cy={end[1]} fill="#72b4ff" r={1.5} />}
          </Fragment>
        ))
    : null
}

export default SpeedVector
