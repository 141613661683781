import { ChangeEvent, FC } from 'react'
import { Checkbox, MenuItem } from '@mui/material'

const MenuItemWithCheckbox: FC<{
  label: string
  checkboxName: string
  onChange: (value: string) => void
  checked: boolean
}> = ({ label, checkboxName, onChange, checked }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.name)
  }

  return (
    <MenuItem component="label">
      {label} <Checkbox name={checkboxName} onChange={handleChange} checked={checked} />
    </MenuItem>
  )
}

export default MenuItemWithCheckbox
