import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

import { getHistoricalFplsRequest } from 'services/exerciseService'

interface ExerciseStateInterface {
  historicalFPLs: HistoryInterface[]
  exerciseFPLs: TrackInterface[]
  selectedFpl?: TrackInterface
  activeRWYs: Record<string, string>
  activeSector: OpsSectorInterface
  routes: string[]
  eppSelected?: string
}

const initialState: ExerciseStateInterface = {
  historicalFPLs: [],
  exerciseFPLs: [],
  activeRWYs: {},
  activeSector: {} as OpsSectorInterface,
  routes: [],
}

export const getHistoricalFplsThunk = createAsyncThunk<HistoryInterface[], void>(
  'exercise/get_historical',
  async () => {
    try {
      const response = await getHistoricalFplsRequest()
      response.data.length = 200
      return response.data.map((item: any) => {
        const newItem: HistoryInterface = {} as HistoryInterface
        newItem.id = item.id
        newItem.c = item.c
        newItem.exitCop = item.exitCop
        newItem.entryCop = item.entryCop
        newItem.adep = item.adep
        newItem.ades = item.ades
        newItem.routePlan = item.routePlan.planPoints
        newItem.rfl = item.rfl
        newItem.cas = item.cas
        newItem.timeShift = 0
        newItem.isAdvanced = true
        newItem.isCorrelated = true
        newItem.isActive = true
        newItem.isTerminated = false
        newItem.tickPositions = item.tickPositions

        const [type, wake] = item.fpl[3].split('/')
        newItem.type = type
        newItem.wake = wake

        newItem.passedPoints = item.tickPositions.reduce((pp: number[], point: any, index: number) => {
          if (point.pP != null) {
            pp.push(index)
          }
          return pp
        }, [])
        newItem.fpl = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 10; i++) {
          newItem.fpl.push(item.fpl[i])
        }

        return newItem
      })
    } catch (e: any) {
      e.code = 0
      throw e
    }
  },
)

const exerciseSlice = createSlice({
  name: 'exercise',
  initialState,
  reducers: {
    setActiveRwys: (state, action) => {
      state.activeRWYs = {
        ...state.activeRWYs,
        ...action.payload,
      }
    },
    addExerciseFPLs: (state, action) => {
      state.exerciseFPLs.push(...action.payload)
    },
    setActiveSector: (state, action) => {
      state.activeSector = action.payload
    },
    selectFPL: (state, action) => {
      const [selected] = state.exerciseFPLs.filter(fpl => fpl.id === action.payload)
      state.selectedFpl = selected
    },
    deselectFPL: state => {
      state.selectedFpl = undefined
    },
    addRoute: (state, action) => {
      state.routes.push(action.payload)
    },
    removeRoute: (state, action) => {
      state.routes = state.routes.filter(id => id !== action.payload)
    },
    clearRoutes: state => {
      state.routes = []
    },
    selectTrackInEpp: (state, action: PayloadAction<string>) => {
      const [{ id }] = state.exerciseFPLs.filter(fpl => fpl.id === action.payload)
      state.eppSelected = id
    },
    deSelectTrackInEpp: state => {
      state.eppSelected = undefined
    },
    updateTimeShift: (state, action) => {
      state.exerciseFPLs = state.exerciseFPLs.map(fpl => {
        if (fpl.id === state.eppSelected) {
          const newFpl = fpl
          newFpl.timeShift += action.payload
          return newFpl
        }
        return fpl
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(getHistoricalFplsThunk.fulfilled, (state, action) => {
      state.historicalFPLs = action.payload
    })
  },
})

const sliceSelector = (state: RootState) => state.exercise

export const selectActiveRwys = createSelector(sliceSelector, state => state.activeRWYs)
export const selectActiveSector = createSelector(sliceSelector, state => state.activeSector)
export const selectHistoricalFPLs = createSelector(sliceSelector, state => state.historicalFPLs)
export const selectExerciseFPLs = createSelector(sliceSelector, state => state.exerciseFPLs)
export const selectSelectedFPL = createSelector(sliceSelector, state => state.selectedFpl)
export const selectEppSelected = createSelector(sliceSelector, state => state.eppSelected)
export const selectRoutes = createSelector(sliceSelector, state => state.routes)

export const {
  setActiveRwys,
  addExerciseFPLs,
  setActiveSector,
  selectFPL,
  deselectFPL,
  addRoute,
  removeRoute,
  clearRoutes,
  selectTrackInEpp,
  deSelectTrackInEpp,
  updateTimeShift,
} = exerciseSlice.actions

export default {
  name: exerciseSlice.name,
  reducer: exerciseSlice.reducer,
}
