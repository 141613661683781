import { styled } from '@mui/material'

export const getEndCoords = (
  startCoords: number[], // a
  labelCoords: number[], // r
  labelBounds: { w: number; h: number },
): { x2: number; y2: number } => {
  const [sx, sy] = startCoords
  const [lx, ly] = labelCoords
  const result = { x2: 0, y2: 0 }

  const xHalf = Math.round(lx + labelBounds.w / 2)
  const yHalf = Math.round(ly + labelBounds.h / 2)

  const topCenter = { x2: xHalf, y2: Math.round(ly) }
  const bottomCenter = { x2: xHalf, y2: Math.round(ly + labelBounds.h) }
  const leftCenter = { x2: Math.round(lx), y2: yHalf }
  const rightCenter = { x2: Math.round(lx + labelBounds.w), y2: yHalf }

  const slope = (ly - sy) / (lx - sx)
  const hsw = (slope * labelBounds.w) / 2
  const hsh = labelBounds.h / 2 / slope
  const hh = labelBounds.h / 2
  const hw = labelBounds.w / 2

  if (-hh <= hsw && hsw <= hh) {
    // line intersects
    if (lx < sx) {
      // right edge;
      return rightCenter
    }
    if (lx > sx) {
      // left edge
      return leftCenter
    }
  }
  if (-hw <= hsh && hsh <= hw) {
    if (ly < sy) {
      // bottom edge
      return bottomCenter
    }
    if (ly > sy) {
      // top edge
      return topCenter
    }
  }

  return result
}

export const LabelLine = styled('line')`
  fill: none;
  stroke-width: 1px;
  stroke: ${({ theme }) => theme.palette.grayscale.white};
`
