import { FC, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import WindowLayer from 'components/Layers/WindowLayer'
import MapLayer from 'components/Layers/MapLayer'
import TrackLayer from 'components/Layers/TrackLayer'
import EventsLayer from 'components/Layers/EventsLayer'
import InfoPanel from 'components/Windows/InfoPanel'
import TimeLayer from 'components/Layers/TimeLayer'

import { useAppDispatch } from 'store/store'
import { setPosition } from 'store/configSlice'
import { getHistoricalFplsThunk, setActiveRwys, setActiveSector } from 'store/exerciseSlice'
import {
  getAirportsThunk,
  getConstraintsThunk,
  getCopsThunk,
  getPerformanceThunk,
  getSectorsThunk,
} from 'store/navDataSlice'

const CWPPage: FC = () => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  const fillGlobals = useCallback(async () => {
    try {
      const position = pathname.replace('/', '')
      dispatch(setPosition(position))

      const sectors = dispatch(getSectorsThunk()).unwrap()
      const sectorsResponse = await sectors
      dispatch(setActiveRwys({ UKLL: '31', UKLN: '15' }))
      dispatch(setActiveSector(sectorsResponse.opsSectors[0]))

      const airports = dispatch(getAirportsThunk()).unwrap()
      const cops = dispatch(getCopsThunk()).unwrap()
      const performance = dispatch(getPerformanceThunk()).unwrap()
      const constraints = dispatch(getConstraintsThunk()).unwrap()

      await airports
      await cops
      await performance
      await constraints

      await dispatch(getHistoricalFplsThunk()).unwrap()
    } catch (e) {
      console.log((e as Error).message)
    }
  }, [dispatch, pathname])

  useEffect(() => {
    fillGlobals()
  }, [fillGlobals])

  return (
    <>
      <MapLayer />
      <TrackLayer />
      <WindowLayer />
      <InfoPanel />
      <EventsLayer />
      <TimeLayer />
    </>
  )
}

export default CWPPage
