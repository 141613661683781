import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'

interface WindowsStateInterface {
  exerciseWindow: boolean
  activeWindow: boolean
  historicalWindow: boolean
  aswToolbox1Window: boolean
  systemTimeWindow: boolean
  workloadWindow: boolean
  exerciseDescriptionWindow: boolean
  rwyWindow: boolean
  speedVectorWindow: boolean
  mapWindow: boolean
  plannerWindow: boolean
  sectorWindow: boolean
  mtcdWindow: boolean
  mainMenu: boolean
}

const initialState: WindowsStateInterface = {
  exerciseWindow: false,
  activeWindow: false,
  historicalWindow: false,
  aswToolbox1Window: false,
  systemTimeWindow: false,
  workloadWindow: false,
  exerciseDescriptionWindow: false,
  rwyWindow: false,
  speedVectorWindow: false,
  mapWindow: false,
  plannerWindow: false,
  sectorWindow: false,
  mtcdWindow: false,
  mainMenu: true,
}

const windowsSlice = createSlice({
  name: 'windows',
  initialState,
  reducers: {
    toggleWindow: (state, action) => {
      const menu: keyof WindowsStateInterface = action.payload
      state[menu] = !state[menu]
    },
    changeWindowState: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key as keyof WindowsStateInterface] = value as boolean
      })
    },
  },
})

const sliceSelector = (state: RootState) => state.windows

export const selectWindows = createSelector(sliceSelector, state => state)
export const selectSystemTimeWindow = createSelector(sliceSelector, state => state.systemTimeWindow)
export const selectSectorWindow = createSelector(sliceSelector, state => state.sectorWindow)
export const selectMainMenuWindows = createSelector(sliceSelector, state => ({
  rwyWindow: state.rwyWindow,
  mtcdWindow: state.mtcdWindow,
}))
export const selectToolboxWindows = createSelector(sliceSelector, state => ({
  speedVectorWindow: state.speedVectorWindow,
}))
export const selectInfoPanelWindows = createSelector(sliceSelector, state => ({
  aswToolbox1Window: state.aswToolbox1Window,
  exerciseWindow: state.exerciseWindow,
  activeWindow: state.activeWindow,
  historicalWindow: state.historicalWindow,
  workloadWindow: state.workloadWindow,
  mainMenu: state.mainMenu,
}))

export const { toggleWindow, changeWindowState } = windowsSlice.actions

export default {
  name: windowsSlice.name,
  reducer: windowsSlice.reducer,
}
