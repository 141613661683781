import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

interface TimeStateInterface {
  start: boolean
  tickValue: number
  tickSpeed: number
  currentTime: number
  startTime: number
  duration: number
}

const initialState: TimeStateInterface = {
  tickValue: 0,
  tickSpeed: 1000,
  duration: 1000 * 60 * 60, // msec * sec * min = 1h
  start: false,
  currentTime: 0,
  startTime: Date.now(),
}

const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    switchStart: state => {
      state.start = !state.start
    },
    setTickValue: (state, action) => {
      state.currentTime = action.payload * 4
      state.tickValue = action.payload
    },
    setTickSpeed: (state, { payload }) => {
      state.tickSpeed = payload
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload
    },
    setStartTime: (state, action) => {
      state.startTime = action.payload
    },
  },
})

const sliceSelector = (state: RootState) => state.time

export const selectTime = createSelector(sliceSelector, state => state)
export const selectTickValue = createSelector(sliceSelector, state => state.tickValue)
export const selectStartTime = createSelector(sliceSelector, state => state.startTime)

export const { switchStart, setTickValue, setTickSpeed, setStartTime, setCurrentTime } = timeSlice.actions

export default {
  name: timeSlice.name,
  reducer: timeSlice.reducer,
}
