import { FC } from 'react'

import Mark from 'components/Track/Mark'
import SpeedVector from 'components/Track/SpeedVector'
import Trail from 'components/Track/Trail'
import Label from 'components/Label/Label'

import { useAppSelector } from 'store/store'
import { selectProjection } from 'store/mapSlice'
import { selectTickValue } from 'store/timeSlice'

const Track: FC<{ track: TrackInterface }> = ({ track }) => {
  const projection = useAppSelector(selectProjection)
  const tick = useAppSelector(selectTickValue)

  const normalizedPosition = tick + track.timeShift
  return (
    track.tickPositions[normalizedPosition] && (
      <g id={track.id}>
        <Label
          position={track.tickPositions[normalizedPosition]}
          projection={projection}
          acType={track.type + track.wake}
          id={track.id}
        />
        <SpeedVector track={track} projection={projection} tick={normalizedPosition} />
        <Trail track={track} projection={projection} tick={normalizedPosition} />
        <Mark
          id={track.id}
          position={track.tickPositions[normalizedPosition]}
          projection={projection}
          route={track.routePlan}
        />
      </g>
    )
  )
}

export default Track
