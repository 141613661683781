import { FC, ReactNode, useRef } from 'react'
import Draggable from 'react-draggable'
import { Box, Button, styled, Typography } from '@mui/material'

// remove after lib update
declare module 'react-draggable' {
  export interface DraggableProps {
    children: ReactNode
  }
}
//

const WindowBody = styled(Box)`
  position: absolute;
  width: auto;
  height: auto;
  max-height: 400px;
  user-select: none;
  background: ${({ theme }) => theme.palette.grayscale.medium};
  border-top-left-radius: ${({ theme }) => theme.spacing(1)};
  border-top-right-radius: ${({ theme }) => theme.spacing(1)};
  border: 1px solid ${({ theme }) => theme.palette.grayscale.black};
  overflow: hidden;
`

const WindowTitle = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.dark};
  padding: ${({ theme }) => theme.spacing(0.25)} ${({ theme }) => theme.spacing(3)};
  cursor: grab;
`

const WindowContent = styled(Box)`
  padding: ${({ theme }) => theme.spacing(0.5)};
`

const CloseButton = styled(Button)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(0.25)};
  right: ${({ theme }) => theme.spacing(0.25)};
`

const WindowFrame: FC<{
  children: ReactNode
  title: string
  onClose?: VoidFunction
  noDrag?: boolean
  position?: { x: number; y: number }
  zIndex?: number
  maxHeight?: number
}> = ({ title, onClose, position, noDrag, zIndex, maxHeight, children }) => {
  const windowRef = useRef(null)

  return (
    <Draggable nodeRef={windowRef} bounds="parent" defaultPosition={position} disabled={noDrag} cancel=".no-drag">
      <WindowBody ref={windowRef} zIndex={zIndex} sx={{ maxHeight }}>
        <WindowTitle>
          <Typography variant="body2" color="primary.light">
            {title}
          </Typography>
          {onClose && (
            <CloseButton color="error" variant="contained" size="small" onClick={onClose} className="no-drag">
              x
            </CloseButton>
          )}
        </WindowTitle>
        <WindowContent className="no-drag">{children}</WindowContent>
      </WindowBody>
    </Draggable>
  )
}

WindowFrame.defaultProps = {
  onClose: undefined,
  noDrag: false,
  position: { x: 10, y: 50 },
  zIndex: 1000,
  maxHeight: undefined,
}
export default WindowFrame
