import { FC, MouseEvent } from 'react'
import { ToggleButton, useTheme } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'store/store'
import { selectSectorWindow, toggleWindow } from 'store/windowsSlice'
import { selectActiveSector } from 'store/exerciseSlice'

const SectorButton: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const currentSector = useAppSelector(selectActiveSector)
  const sectorWindow = useAppSelector(selectSectorWindow)

  const handleToggle = (_: MouseEvent<HTMLElement>, value: string) => {
    dispatch(toggleWindow(value))
  }

  return (
    <ToggleButton
      value="sectorWindow"
      selected={sectorWindow}
      onClick={handleToggle}
      sx={{ color: theme.palette.success.light }}
    >
      {currentSector?.sector}-{currentSector?.freq}
    </ToggleButton>
  )
}

export default SectorButton
