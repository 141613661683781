import { ForwardedRef, forwardRef, LegacyRef } from 'react'
import { useAppSelector } from 'store/store'
import { selectLabelFontSize } from 'store/configSlice'
import { styled } from '@mui/material'

export const LabelRowStyled = styled('g')<{ rownumber: number; fontSize: number; isthree?: 0 | 1; isfour?: 0 | 1 }>`
  font-size: ${({ fontSize }) => fontSize}px;
  transform: translate(2px, ${({ rownumber, fontSize }) => 2 + fontSize * rownumber}px);

  &:nth-of-type(4) {
    visibility: ${({ isthree }) => (isthree === 1 ? 'visible' : 'hidden')};
  }

  &:nth-of-type(5) {
    visibility: ${({ isfour }) => (isfour === 1 ? 'visible' : 'hidden')};
  }
`

const LabelRow = forwardRef<
  ForwardedRef<SVGGElement>,
  ChildrenInterface & { rowNumber: number; isThree?: boolean; isFour?: boolean }
>((props, ref) => {
  const { children, rowNumber, isThree, isFour } = props
  const labelFont = useAppSelector(selectLabelFontSize)

  return (
    <LabelRowStyled
      ref={ref as LegacyRef<SVGGElement>}
      fontSize={labelFont}
      isthree={isThree ? 1 : 0}
      isfour={isFour ? 1 : 0}
      rownumber={rowNumber}
    >
      {children}
    </LabelRowStyled>
  )
})

LabelRow.defaultProps = {
  isThree: false,
  isFour: false,
}

export default LabelRow
