import { FC, useCallback, useRef } from 'react'
import { GeoJsonProperties } from 'geojson'
import { geoPath, GeoPermissibleObjects } from 'd3-geo'
import { Selection } from 'd3-selection'

import LineMapObject from 'components/Map/LineMapObject'
import PointMapObject from 'components/Map/PointMapObject'

import { useAppSelector } from 'store/store'
import { selectFreqData, selectLinesData, selectPointsData, selectProjection, selectSectorsData } from 'store/mapSlice'

import { getSvgPointCoordinate } from 'utils/map'

const MapLayer: FC = () => {
  const svgRef = useRef<SVGSVGElement>(null)
  const projection = useAppSelector(selectProjection)
  const sectors = useAppSelector(selectSectorsData)
  const lines = useAppSelector(selectLinesData)
  const points = useAppSelector(selectPointsData)
  const freqs = useAppSelector(selectFreqData)

  const appendGeometry = useCallback(
    <T extends GeoPermissibleObjects & GeoJsonProperties>(svg: Selection<any, any, any, any>, datum: T) => {
      try {
        svg.selectAll('path').remove()
        svg.append('path').datum<T>(datum).attr('d', geoPath<T>(projection))
      } catch (e) {
        console.log(e)
      }
    },
    [projection],
  )

  const appendPoints = useCallback(
    (svg: Selection<any, any, any, any>, data: MapPointInterface[], isFreq?: boolean) => {
      try {
        svg.selectAll('.points').remove()
        svg
          .selectAll('.points')
          .data<MapPointInterface>(data)
          .join(group => {
            const enter = group.append('g').attr('class', 'points')
            if (!isFreq) {
              enter
                .append('use')
                .attr('xlink:href', '#point')
                .attr('x', d => getSvgPointCoordinate(d, projection)[0])
                .attr('y', d => getSvgPointCoordinate(d, projection)[1])
            }
            enter
              .append('text')
              .attr('y', d => getSvgPointCoordinate(d, projection, true)[1])
              .attr('x', d => getSvgPointCoordinate(d, projection, true)[0])
              .attr('font-size', '10px')
              .selectAll('tspan')
              .data<string>(d => d.name.split('/'))
              .join(textSelection => {
                const textSpan = textSelection.append('tspan').text(d => d)
                textSpan
                  .filter((d, i) => i > 0)
                  .attr('dy', '16px')
                  // @ts-ignore
                  .attr('x', (d, i, a) => a[0].parentElement!.attributes.x.value)

                return textSpan
              })

            return enter
          })
      } catch (e) {
        console.log((e as Error).message)
      }
    },
    [projection],
  )

  const drawGeometry = useCallback(
    (isLine: boolean) =>
      Object.values(isLine ? lines : sectors).map(value => (
        <LineMapObject key={value.id} data={value} drawFunction={appendGeometry} isLine={isLine} />
      )),
    [appendGeometry, sectors, lines],
  )

  const drawPoints = useCallback(
    (isFreq = false) =>
      Object.entries(isFreq ? freqs : points).map(([key, value]) => (
        <PointMapObject key={key} data={value} id={key} drawFunction={appendPoints} isFreq={isFreq} />
      )),
    [appendPoints, points, freqs],
  )

  return (
    <svg
      ref={svgRef}
      width="100%"
      height="100%"
      style={{ position: 'absolute', top: 0, left: 0, zIndex: 0, userSelect: 'none' }}
    >
      <defs>
        <polygon id="point" points="0,8 8,8 4,0" height={8} width={8} />
      </defs>
      {drawGeometry(false)}
      {drawGeometry(true)}
      {drawPoints(false)}
      {drawPoints(true)}
    </svg>
  )
}

export default MapLayer
