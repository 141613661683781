import { styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

const WindowTable = styled(DataGrid)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border: none;
  border-radius: 0;
  border-top: 0.5px solid ${({ theme }) => theme.palette.primary.light};

  & .MuiDataGrid-row {
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.dark};
    }
    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.dark};
      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.dark};
      }
    }
  }

  & .MuiDataGrid-columnHeaders,
  & .MuiDataGrid-cell {
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.light};
  }

  & .MuiDataGrid-footerContainer {
    border-top: 1px solid ${({ theme }) => theme.palette.primary.light};
  }

  & .MuiDataGrid-columnSeparator {
    display: none;
  }

  & .MuiToolbar-root {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    & .MuiButtonBase-root {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      &.Mui-disabled {
        color: ${({ theme }) => theme.palette.primary.light};
      }
    }
  }
  && .MuiDataGrid-cell:focus,
  && .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }
  & .disabled {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`

export default WindowTable
