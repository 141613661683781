import { FC, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { GridColumns, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid'

import WindowFrame from 'components/Windows/WindowFrame'
import WindowTable from 'components/Windows/components/Table'

import { useAppDispatch, useAppSelector } from 'store/store'
import { toggleWindow } from 'store/windowsSlice'

import { selectOpsSectors } from 'store/navDataSlice'
import { selectActiveSector, setActiveSector } from 'store/exerciseSlice'

const columns: GridColumns = [
  { field: 'allowance', headerName: 'Sector', disableColumnMenu: true, width: 96 },
  {
    field: 'vLimit',
    headerName: 'Limits',
    disableColumnMenu: true,
    width: 128,
    valueGetter: ({ row }: GridValueGetterParams<OpsSectorInterface>) =>
      `${row.vLimit.lower > 10000 ? 'FL' : ''}${row.vLimit.lower / 100}-FL${row.vLimit.upper / 100}`,
  },
  { field: 'configuration', headerName: 'Configurations', disableColumnMenu: true, width: 152 },
]

const SectorsWindow: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const sectors = useAppSelector(selectOpsSectors)
  const currentSector = useAppSelector(selectActiveSector)

  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([currentSector.id])
  const onSelect = (newSelectionModel: GridSelectionModel) => {
    setSelectedRows(newSelectionModel)
    dispatch(setActiveSector(sectors.filter(sector => sector.id === newSelectionModel[0])[0]))
  }

  const handleClose = () => {
    dispatch(toggleWindow('sectorWindow'))
  }

  return (
    <WindowFrame title="Sectors" onClose={handleClose} zIndex={theme.zIndex.drawer} maxHeight={600}>
      <Box sx={{ flexGrow: 1 }} width={380}>
        <WindowTable
          // for all tables
          autoHeight
          pageSize={10}
          rowsPerPageOptions={[10]}
          density="compact"
          hideFooterSelectedRowCount
          // ---------------
          columns={columns}
          rows={sectors}
          onSelectionModelChange={onSelect}
          selectionModel={selectedRows}
        />
      </Box>
    </WindowFrame>
  )
}

export default SectorsWindow
