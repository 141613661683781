import { FC } from 'react'
import dayjs from 'dayjs'

import { useAppSelector } from 'store/store'
import { selectStartTime } from 'store/timeSlice'

const RoutePoint: FC<{ start: number[]; end: number[]; name: string; tickOver: number }> = ({
  start,
  end,
  name,
  tickOver,
}) => {
  const startTime = useAppSelector(selectStartTime)

  return (
    <g fill="lightgreen" fontSize={10}>
      <line x1={start[0]} y1={start[1]} x2={end[0]} y2={end[1]} stroke="lightgreen" strokeWidth={1} />
      <use href="#point" x={end[0] - 4} y={end[1] - 4} />
      <text x={end[0] + 4} y={end[1]}>
        {name}
      </text>
      <text x={end[0] + 4} y={end[1] + 12}>
        {tickOver && dayjs(startTime + tickOver * 4 * 1000).format('HH:mm')}
      </text>
    </g>
  )
}

export default RoutePoint
