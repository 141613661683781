/* eslint-disable no-param-reassign */
import * as geolib from 'geolib'
import type { LineString } from 'geojson'

import { useAppSelector } from 'store/store'
import { selectActiveRwys, selectHistoricalFPLs } from 'store/exerciseSlice'
import {
  selectAirports,
  selectBasicSectors,
  selectConstraints,
  selectCops,
  selectPerformance,
} from 'store/navDataSlice'
import { selectSectorsData } from 'store/mapSlice'
// import { selectTickValue } from 'store/timeSlice'

import AirspeedConverter from 'utils/AirspeedConverter'
import { degreesRadiansConverter } from 'utils/converters'

const useNavCalculations = () => {
  const runways = useAppSelector(selectActiveRwys)
  const airports = useAppSelector(selectAirports)
  const cops = useAppSelector(selectCops)
  const basicSectors = useAppSelector(selectBasicSectors)
  const sectorsGeometry = useAppSelector(selectSectorsData)
  const performance = useAppSelector(selectPerformance)
  // const tickValue = useAppSelector(selectTickValue)
  const historicalFPLs = useAppSelector(selectHistoricalFPLs)
  const constraints = useAppSelector(selectConstraints)

  const availableAirports: string[] = Object.keys(airports)

  const applyConstraints = (fpl: TrackInterface) => {
    const [appliedConstraints] = constraints.filter(
      constraint => constraint.ades === fpl.ades && constraint.points.includes(fpl.exitCop),
    )
    if (appliedConstraints) {
      fpl.xfl = fpl.rfl >= appliedConstraints.xfl ? appliedConstraints.xfl : fpl.rfl
    }
  }

  const getInBasicSectorName = (currentLatitude: number, currentLongitude: number, altitude: number): string | null => {
    const [inSectorName] = basicSectors
      .filter(bs => altitude > bs.vLimit.lower && altitude <= bs.vLimit.upper)
      .filter(ts =>
        geolib.isPointInPolygon(
          {
            latitude: currentLatitude,
            longitude: currentLongitude,
          },
          (sectorsGeometry[ts.name as keyof SectorsInterface].geometry as LineString).coordinates.map(
            ([latitude, longitude]) => ({ latitude, longitude }),
          ),
        ),
      )
    return inSectorName ? inSectorName.sector : null
  }

  const checkIfOnSpeed = (position: TrackTickPositionInterface, currentPerformance: string[]) => {
    if (position.isOS) {
      if (position.sS > parseInt(currentPerformance[16], 10)) {
        position.sS = parseInt(currentPerformance[5], 10)
      } else if (position.sS < parseInt(currentPerformance[15], 10)) {
        position.sS = parseInt(currentPerformance[15], 10)
      }
    } else {
      position.sS = parseInt(currentPerformance[4], 10)
    }
  }

  const calculateDeparture = (fpl: TrackInterface) => {
    fpl.adepRunway = runways[fpl.adep]

    const [currentSID] = airports[fpl.adep].Sid.filter(
      sid =>
        sid.Name.substring(0, 4).toLowerCase() === fpl.routePlan[1].name.substring(0, 4).toLowerCase() &&
        sid.Runways === fpl.adepRunway,
    )

    if (currentSID) {
      const departurePoint = { ...fpl.routePlan[0] }
      fpl.routePlan.splice(0, 2)
      const departureWaypoints = currentSID.Sid_Waypoint
      const tempPoints: TrackRoutePoint[] = []
      const { length } = departureWaypoints

      departureWaypoints.forEach((waypoint, index) => {
        const tempWaypoint: ChartWaypointInterface = {} as ChartWaypointInterface

        if (waypoint.Type === 'DmeIntc') {
          const dmePoint = geolib.computeDestinationPoint(
            {
              latitude: waypoint.Latitude,
              longitude: waypoint.Longitude,
            },
            parseInt(waypoint.DMEtoIntercept, 10) * 1.852 * 1000,
            parseInt(waypoint.Hdg_Crs_value, 10) + 6,
          )
          tempWaypoint.Name = waypoint.Name + waypoint.DMEtoIntercept
          tempWaypoint.Latitude = dmePoint.latitude
          tempWaypoint.Longitude = dmePoint.longitude
        } else {
          tempWaypoint.Name = waypoint.Name
          tempWaypoint.Latitude = waypoint.Latitude
          tempWaypoint.Longitude = waypoint.Longitude
        }

        const nextPoint: PointInterface =
          length > index + 1
            ? {
                latitude: departureWaypoints[index + 1].Latitude,
                longitude: departureWaypoints[index + 1].Longitude,
              }
            : {
                latitude: fpl.routePlan[0].latitude,
                longitude: fpl.routePlan[0].longitude,
              }
        // dist in nm
        const dist =
          geolib.getDistance({ latitude: tempWaypoint.Latitude, longitude: tempWaypoint.Longitude }, nextPoint, 1) /
          1852

        tempPoints.push({
          name: tempWaypoint.Name,
          isCop: cops.includes(tempWaypoint.Name),
          latitude: tempWaypoint.Latitude,
          longitude: tempWaypoint.Longitude,
          dist,
        } as TrackRoutePoint)
      })
      fpl.routePlan.unshift(...tempPoints)
      fpl.tickPositions[0].h = geolib.getGreatCircleBearing(fpl.tickPositions[0].cP, fpl.routePlan[0]) - 6
      fpl.routePlan.unshift(departurePoint)
    }
  }

  const calculateArrival = (fpl: TrackInterface) => {
    fpl.adesRunway = runways[fpl.ades]
    const airport = airports[fpl.ades]
    const [approach] = airport.Approach.filter(rwy => rwy.Name === `ILS${fpl.adesRunway}`)
    const approachWaypoints: ChartWaypointInterface[] = JSON.parse(JSON.stringify(approach.App_Waypoint))
    const [star] = airport.Star.filter(
      airportStar =>
        airportStar.Name.substring(0, 4) === fpl.routePlan.at(-2)?.name.substring(0, 4) &&
        airportStar.Runways === fpl.adesRunway,
    )
    const arrivalWaypoints: ChartWaypointInterface[] = []

    if (star) {
      const starCopy: StarInterface = JSON.parse(JSON.stringify(star))
      if (approachWaypoints[0].Name === starCopy.Star_Waypoint.at(-1)?.Name) {
        starCopy.Star_Waypoint.pop()
      }
      arrivalWaypoints.push(...starCopy.Star_Waypoint)
      fpl.routePlan.splice(-2)
    }

    const runwayWaypointIndex = approachWaypoints.findIndex(waypoint => waypoint.Type === 'Runway')
    if (approachWaypoints.length > runwayWaypointIndex + 1) {
      approachWaypoints.splice(runwayWaypointIndex + 1)
      arrivalWaypoints.push(...approachWaypoints)
    }

    const tempPoints: TrackRoutePoint[] = []
    const arrivalWaypointsLength = arrivalWaypoints.length
    arrivalWaypoints.forEach((waypoint, index) => {
      const nextPoint: PointInterface =
        arrivalWaypointsLength > index + 1
          ? {
              latitude: arrivalWaypoints[index + 1].Latitude,
              longitude: arrivalWaypoints[index + 1].Longitude,
            }
          : {
              latitude: waypoint.Latitude,
              longitude: waypoint.Longitude,
            }
      // dist in nm
      const dist =
        geolib.getDistance({ latitude: waypoint.Latitude, longitude: waypoint.Longitude }, nextPoint, 1) / 1852
      tempPoints.push({
        name: waypoint.Name,
        isCop: cops.includes(waypoint.Name),
        isRunway: waypoint.Type === 'Runway',
        latitude: waypoint.Latitude,
        longitude: waypoint.Longitude,
        dist,
      } as TrackRoutePoint)
    })

    if (fpl.routePlan.at(-1)?.isAdes) {
      fpl.routePlan.pop()
    }
    fpl.routePlan.push(...tempPoints)
  }

  const calculateSpeeds = (position: TrackTickPositionInterface, fpl: TrackInterface) => {
    const [currentPerformance] = fpl.performance.perf_layers.filter(
      layer => parseInt(layer.alt, 10) >= position.a / 100,
    )
    position.minS = parseFloat(currentPerformance.perf[15])
    // TODO: add condition - above fl250  - if min ias>250kt -> min ias===250kt, maybe more conditions
    if (position.minS > 180 && position.a < 25000) {
      position.minS = 180
    }
    position.maxS = parseFloat(currentPerformance.perf[16])

    // calc speed
    if (position.a > 5000 && position.a < 10000) {
      if (currentPerformance.perf) {
        if (position.isApp) {
          checkIfOnSpeed(position, currentPerformance.perf)
        } else {
          position.sS = parseInt(currentPerformance.perf[0], 10)
        }
      } else if (position.isApp && position.sS >= 250) {
        position.sS = 250
      } else if (fpl.cas > 250) {
        position.sS = 250
      } else {
        position.sS = fpl.cas
      }
    }
    if (position.a > 4000 && position.a < 7500 && position.isApp) {
      if (currentPerformance.perf) {
        checkIfOnSpeed(position, currentPerformance.perf)
      } else if (fpl.cas > 220 && position.sS > 220) {
        position.sS = 220
      }
    }
    if (position.a > 3000 && position.a <= 4000 && position.isApp) {
      if (currentPerformance.perf) {
        checkIfOnSpeed(position, currentPerformance.perf)
      } else if (fpl.cas > 200 && position.sS > 200) {
        position.sS = 200
      }
    }
    if (position.a <= 3000) {
      if (currentPerformance.perf) {
        checkIfOnSpeed(position, currentPerformance.perf)
      } else if (fpl.cas > 180) {
        position.sS = 180
      }
    }
    if (position.a >= 10000 && position.a < 25000) {
      if (!position.isOS) {
        if (currentPerformance.perf) {
          position.sS = parseInt(currentPerformance.perf[0], 10)
        } else {
          position.sS = fpl.cas
        }
      } else if (position.isOS && currentPerformance.perf) {
        if (position.sS > position.maxS) {
          position.sS = position.maxS
        } else if (position.sS < position.minS) {
          position.sS = position.minS
        }
      }
    }
    if (position.a >= 25000) {
      const speeds = AirspeedConverter.calcSpeedsFromMach(position.M, position.a)
      if (currentPerformance.perf) {
        if (position.isOS) {
          position.sS = speeds.cas
          if (position.sS > position.maxS) {
            position.sS = position.maxS
          } else if (position.sS < position.minS) {
            position.sS = position.minS
          }
        } else {
          position.sS = parseInt(currentPerformance.perf[0], 10)
        }
      } else {
        position.sS = speeds.cas
      }
    }

    if (position.s - position.sS < -1) {
      position.s += 2
    } else if (position.s - position.sS >= 1) {
      position.s -= 1
    } else if (position.s - position.sS === -1) {
      position.s += 1
    }
    const { tas, mach } = AirspeedConverter.calcSpeedsFromCAS(position.s, position.a)
    position.calcM = mach
    position.t = tas
    position.gs = tas // TODO wind effect
    //
  }

  const calculatePerformance = (position: TrackTickPositionInterface, fplPerformance: PerformanceInterface) => {
    const [currentPerformance] = fplPerformance.perf_layers.filter(layer => parseInt(layer.alt, 10) >= position.a / 100)

    position.stdRoc = 125
    position.maxRoc = 230
    position.r = position.r || 0

    position.maxFL = parseInt(fplPerformance.perf[0], 10)

    if (position.sA / 100 > position.maxFL) {
      position.sA = position.maxFL * 100
    }

    // here we calc standard and max roc
    if (position.isApp) {
      if (position.a > position.sA) {
        position.stdRoc = (AirspeedConverter.calcSpeedsFromCAS(position.s, position.a).tas * 5) / 15
        position.maxRoc = parseInt(currentPerformance.perf[13], 10) / 15
      } else {
        position.stdRoc = 125
        position.maxRoc = parseInt(currentPerformance.perf[11], 10) / 15
      }
    } else if (position.a > position.sA) {
      position.stdRoc = parseInt(currentPerformance.perf[12], 10) / 15
      position.maxRoc = parseInt(currentPerformance.perf[13], 10) / 15
    } else {
      position.stdRoc = parseInt(currentPerformance.perf[10], 10) / 15
      position.maxRoc = parseInt(currentPerformance.perf[11], 10) / 15
    }

    if (position.stdRoc > position.r && position.stdRoc + 20 < position.maxRoc) {
      position.stdRoc += 20
    } else if (position.stdRoc <= position.r && position.r + 20 < position.maxRoc) {
      position.stdRoc = position.r + 20
    }
  }

  const calculateAltChange = (position: TrackTickPositionInterface) => {
    // calc alt change
    const altDiff = Math.abs(position.a - position.sA)
    if (altDiff <= 300 && position.stdRoc > 300 / 15) {
      position.stdRoc = 300 / 15
    } else if (altDiff <= 1200 && position.stdRoc > 1000 / 15) {
      position.stdRoc = 1000 / 15
    }
    const selectedRoc = altDiff > position.stdRoc ? position.stdRoc : altDiff / 2

    if (position.sA > position.a && position.isDesc && position.r > 0) {
      position.r -= 30
      if (position.r < 0) {
        position.r = 20
        // TODO: maybe create climbStatus = 'climb'|'descent'|'none' ?
        position.isDesc = false
        position.isClimb = true
      }
    } else if (position.sA < position.a && position.isClimb && position.r > 0) {
      position.r -= 30
      if (position.r < 0) {
        position.r = 20
        position.isDesc = true
        position.isClimb = false
      }
    } else if (!position.isDesc && !position.isClimb) {
      if (position.a > position.sA) {
        position.isDesc = true
      } else if (position.a < position.sA) {
        position.isClimb = true
      }
    } else {
      const rocDiff = Math.abs(selectedRoc - position.r)
      if (selectedRoc > position.r) {
        position.r = rocDiff > selectedRoc / 3 && rocDiff <= selectedRoc ? position.r + selectedRoc / 3 : selectedRoc
      } else if (selectedRoc < position.r) {
        position.r = rocDiff > 20 ? position.r - 20 : selectedRoc
      }
    }

    if (position.isClimb) {
      position.a += position.r
    } else if (position.isDesc) {
      position.a -= position.r
    }
  }

  const calculateHeading = (position: TrackTickPositionInterface, nextPoint: TrackRoutePoint) => {
    // calcHeading
    if (!position.isOH && !position.isOO) {
      position.sH = geolib.getGreatCircleBearing(position.cP, nextPoint) - 6
    }

    const stdROT = (Math.tan(((position.a > 7000 ? 30 : 35) * Math.PI) / 180) * 1091) / position.gs
    const diffBetweenHeadingAndSelectedHeading = position.h - position.sH
    const absDiffBetweenHeadingAndSelectedHeading = Math.abs(diffBetweenHeadingAndSelectedHeading)

    if (position.isOO) {
      // 4 is supposed to be secondsForTick
      if (absDiffBetweenHeadingAndSelectedHeading >= stdROT * 4 || !position.isOSt) {
        if (position.rot < stdROT) {
          position.rot += stdROT * 0.2
        }
        position.h += position.rot * (position.oS === 'R' ? 4 : -4)
      } else {
        position.rot = absDiffBetweenHeadingAndSelectedHeading / 2
        position.h += position.rot * (position.oS === 'R' ? 1 : -1)
      }
      if (absDiffBetweenHeadingAndSelectedHeading >= 90) {
        position.isOSt = false
      }
      if (absDiffBetweenHeadingAndSelectedHeading <= 5 && position.isOSt) {
        position.isOO = false
      }
    } else if (absDiffBetweenHeadingAndSelectedHeading >= stdROT * 4) {
      if (position.rot < stdROT) {
        position.rot += stdROT * 0.2
      }
      position.h += position.rot * ((diffBetweenHeadingAndSelectedHeading + 360) % 360 > 180 ? 4 : -4)
    } else {
      position.rot = absDiffBetweenHeadingAndSelectedHeading / 2
      position.h += position.rot * ((diffBetweenHeadingAndSelectedHeading + 360) % 360 > 180 ? 1 : -1)
    }
    if (position.h < 0) {
      position.h += 360
    }
    if (position.h > 360) {
      position.h -= 360
    }
  }

  const calculateDistances = (position: TrackTickPositionInterface, route: TrackRoutePoint[], exitCop: string) => {
    // calc distances
    const exitPointIndex = route.findIndex(point => point.name === exitCop)

    position.cP = geolib.computeDestinationPoint(position.cP, (position.t * 1.852 * 1000) / 60 / 15, position.h + 6)

    position.nP = position.nP || 1
    position.prP = position.prP || position.nP - 1
    const routeDistance = route.reduce((acc, r, ind) => (ind >= position.nP ? acc + r.dist : acc), 0)
    let distanceToPrevPoint = 999999999

    position.distToNp = geolib.convertDistance(geolib.getDistance(position.cP, route[position.nP]), 'sm')

    position.distToAdes = routeDistance + position.distToNp

    while (position.distToNp <= position.gs / 3600 && route.length > position.nP + 1) {
      position.nP += 1
      position.distToNp = geolib.convertDistance(geolib.getDistance(position.cP, route[position.nP]), 'sm')
    }

    // TODO: why this can be true?
    if (position.nP === position.prP) {
      distanceToPrevPoint = position.distToNp
    } else {
      const tempDisToPrevPoint = geolib.convertDistance(geolib.getDistance(position.cP, route[position.prP]), 'sm')
      if (tempDisToPrevPoint < distanceToPrevPoint) {
        distanceToPrevPoint = tempDisToPrevPoint
      } else {
        // TODO: maybe we don't need ++?
        position.prP += 1
        position.pP = true
      }
    }

    if (exitPointIndex >= 0 && position.xfl) {
      const distToExCop = geolib.convertDistance(geolib.getDistance(position.cP, route[exitPointIndex]), 'sm')
      if (distToExCop < Math.abs(position.a - position.xfl * 100 + 4000) / 300) {
        if (position.sA > position.xfl * 100) {
          position.tod = true
          if (!position.isAssumed) {
            position.sA = position.xfl * 100
          }
        } else {
          position.tod = false
        }
      }
      if (distToExCop < Math.abs(position.a - position.xfl * 100 + 10000) / 300) {
        if (position.sA < position.xfl * 100) {
          position.toc = true
          if (!position.isAssumed) {
            position.sA = position.xfl * 100
          }
        } else {
          position.toc = false
        }
      }
    }

    if (position.distToAdes < position.a / 300 && position.a > 4000) {
      position.isApp = true
      if (position.a - position.sA < 100) {
        position.tod = true
        if (position.a > 12000 && !position.isAssumed && position.sA > 12000) {
          position.sA = 12000
        }
      } else {
        position.tod = false
      }
      // TODO Almost done Request descend
    }
  }

  const calculateILSInterception = (position: TrackTickPositionInterface, route: TrackRoutePoint[], ades: string) => {
    // calc ils interception
    const turnRadius = ((position.t ** 2 / 11.26) * Math.tan((30 * Math.PI) / 180)) / 6076
    const routeLength = route.length
    let rwyDetails: RWYInterface | undefined
    if (availableAirports.includes(ades)) {
      rwyDetails = airports[ades].Runways[runways[ades]]
    }

    if (rwyDetails) {
      position.isIAA = geolib.isPointInPolygon(position.cP, rwyDetails.approachArea)

      // TODO: in this case it will be always true - implement "Cleared ILS" button is needed to control this
      position.isCILS = position.isCILS || true
      if (position.a < 5100) {
        if (
          position.sA !== rwyDetails.elevation &&
          position.isCILS &&
          position.a - rwyDetails.elevation >
            Math.tan((3 * Math.PI) / 180) *
              6076 *
              geolib.convertDistance(geolib.getDistance(position.cP, rwyDetails.threshold), 'sm') &&
          position.isIAA &&
          !position.isGA
        ) {
          position.sA = rwyDetails.elevation
        }
        if (position.isIAA && position.isCILS && !position.isGA) {
          if (
            position.isOH &&
            geolib.getDistanceFromLine(
              position.cP,
              rwyDetails.threshold,
              geolib.computeDestinationPoint(
                rwyDetails.threshold,
                // this means 20nm long final - prev ver had 10 nm, 20 is real ils range
                20 * 1.852 * 1000,
                (rwyDetails.heading + 180 + 6) % 360,
              ),
            ) /
              1852 <
              turnRadius / 1.3 &&
            degreesRadiansConverter.getDiffBetweenAngles(rwyDetails.heading, position.h) < 31
          ) {
            position.nP = routeLength - 1
            position.isOH = false
          }
        } else if (position.isGA && position.sA < 3000) {
          position.sA = 3000
        }
      }

      if (position.isGA && !position.isIAA) {
        position.isGA = false
      }
    }

    if (position.distToNp < turnRadius * 3) {
      if (!route[position.nP].isRunway) {
        position.nP += 1
      } else {
        if (true) {
          // this.isSelfApproach = true
          position.isOH = true
          position.sH = ((rwyDetails?.heading || 0) + 180) % 360
        } else if (position.sA > 3000) {
          position.nP = routeLength - 3
          position.sA = 3000
          position.prP = position.nP - 1
        }
        if (position.a < (rwyDetails?.elevation || 0) + 400 && position.distToNp < 1) {
          position.nP = routeLength
        }
      }
    }
  }

  const calculateCurrentSector = (position: TrackTickPositionInterface, tick: number, sectors: string[]) => {
    if (tick % 5 === 0) {
      position.iBSN =
        position.cP.latitude > 47.47 &&
        position.cP.longitude > 22 &&
        position.cP.latitude < 52.2 &&
        position.cP.longitude < 28
          ? getInBasicSectorName(position.cP.latitude, position.cP.longitude, position.a)
          : null
    }
    if (position.iBSN) {
      sectors.push(position.iBSN)
    }
  }

  const calculateTickPositions = (fpl: TrackInterface, tickValue = 0) => {
    fpl.sectors = []
    let incrementalTickValue = tickValue

    while (fpl.isActive) {
      const currentPosition: TrackTickPositionInterface = JSON.parse(
        JSON.stringify(fpl.tickPositions[incrementalTickValue]),
      )

      // TODO: why this can === 1?
      if (fpl.routePlan.length === 1) {
        // TODO: maybe make status="terminated"|"initial"...
        fpl.isTerminated = true
        fpl.isActive = false
        break
      }

      calculateHeading(currentPosition, fpl.routePlan[currentPosition.nP || 1])
      calculateSpeeds(currentPosition, fpl)

      if (currentPosition.a === currentPosition.sA) {
        currentPosition.isDesc = false
        currentPosition.isClimb = false
      } else {
        calculatePerformance(currentPosition, fpl.performance)
        calculateAltChange(currentPosition)
      }

      calculateDistances(currentPosition, fpl.routePlan, fpl.exitCop)

      calculateILSInterception(currentPosition, fpl.routePlan, fpl.ades)
      // TODO: temporary +1 till fix the arrival bug
      if (fpl.routePlan.length === currentPosition.nP + 1) {
        fpl.isTerminated = true
        fpl.isActive = false
        currentPosition.a = 1000
      }

      if (fpl.isTerminated) {
        currentPosition.pP = true
        currentPosition.nPN = fpl.routePlan.at(-1)!.name
        currentPosition.cP = { ...fpl.routePlan.at(-1)! }
      } else {
        currentPosition.nPN = fpl.routePlan[currentPosition.nP].name
      }

      calculateCurrentSector(currentPosition, incrementalTickValue, fpl.sectors)

      fpl.tickPositions.push(currentPosition)
      if (fpl.tickPositions[incrementalTickValue].nPN !== currentPosition.nPN) {
        fpl.routePlan[fpl.tickPositions[incrementalTickValue].nP].tickOver = incrementalTickValue
      }
      incrementalTickValue += 1

      // calcPassedPoints(flight, startDate)
      // calcWorkload(flight)
    }

    fpl.sectors = Array(...new Set(fpl.sectors))
  }

  const createFlight = (ids: string[]): TrackInterface[] => {
    return historicalFPLs
      .filter(fpl => ids.includes(fpl.id))
      .map(fpl => {
        const airportsList = Object.keys(runways)
        const updatedFpl: TrackInterface = JSON.parse(JSON.stringify(fpl))
        updatedFpl.tickPositions[0].isAdvanced = false
        updatedFpl.tickPositions[0].rot = 0
        updatedFpl.performance = performance[updatedFpl.type] ? performance[updatedFpl.type] : performance.B738

        if (airportsList.includes(updatedFpl.adep)) {
          calculateDeparture(updatedFpl)
        }
        if (airportsList.includes(updatedFpl.ades)) {
          calculateArrival(updatedFpl)
        }
        applyConstraints(updatedFpl)
        calculateTickPositions(updatedFpl)
        updatedFpl.dx = 0
        updatedFpl.dy = 0
        return updatedFpl
      })
  }

  return { calculateDeparture, calculateArrival, createFlight }
}

export default useNavCalculations
