import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'store/store'

interface ConfigStateInterface {
  position: CWPType | ''
  vectorValue: number
  isVectorShown: boolean
  isVectorMarksShown: boolean
  historyValue: number
  isHistoryShown: boolean
  sectorFilled: boolean
  labelFontSize: number
  showLineThree: boolean
  showLineFour: boolean
}

const initialState: ConfigStateInterface = {
  position: '',
  vectorValue: 2,
  isVectorMarksShown: true,
  isVectorShown: true,
  historyValue: 7,
  isHistoryShown: true,
  sectorFilled: true,
  labelFontSize: 16,
  showLineThree: false,
  showLineFour: false,
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setPosition: (state, action) => {
      state.position = action.payload
    },
    toggleVector: state => {
      state.isVectorShown = !state.isVectorShown
    },
    toggleVectorMarks: state => {
      state.isVectorMarksShown = !state.isVectorMarksShown
    },
    setVectorValue: (state, action) => {
      state.vectorValue = action.payload
    },
    setHistoryValue: (state, action) => {
      state.historyValue = action.payload
    },
    toggleSectorFilled: state => {
      state.sectorFilled = !state.sectorFilled
    },
    setLabelFontSize: (state, action) => {
      state.labelFontSize = action.payload
    },
    toggleLabelLine: (state, action) => {
      if (action.payload === 'three') {
        state.showLineThree = !state.showLineThree
      }
      if (action.payload === 'four') {
        state.showLineFour = !state.showLineFour
      }
    },
  },
})

const sliceSelector = (state: RootState) => state.config

export const selectPosition = createSelector(sliceSelector, state => state.position)
export const selectVector = createSelector(sliceSelector, ({ vectorValue, isVectorShown, isVectorMarksShown }) => ({
  vectorValue,
  isVectorShown,
  isVectorMarksShown,
}))
export const selectFilled = createSelector(sliceSelector, state => state.sectorFilled)
export const selectHistoryValue = createSelector(sliceSelector, state => state.historyValue)
export const selectLabelFontSize = createSelector(sliceSelector, state => state.labelFontSize)
export const selectLabelLines = createSelector(sliceSelector, state => ({
  three: state.showLineThree,
  four: state.showLineFour,
}))

export const {
  setPosition,
  toggleVector,
  setVectorValue,
  toggleSectorFilled,
  setLabelFontSize,
  toggleVectorMarks,
  setHistoryValue,
  toggleLabelLine,
} = configSlice.actions

export default {
  name: configSlice.name,
  reducer: configSlice.reducer,
}
