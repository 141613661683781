import { createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import type { RootState } from 'store/store'
import { loginRequest, validateTokenRequest } from 'services/authService'

import { getUserData, persistTokenData, removeTokenData } from 'utils/storage'

import { isFulfilledAction, isPendingAction, isRejectedAction } from './utils/loadingMatchers'

interface AuthStateInterface {
  isLoggedIn: boolean
  loading: boolean
  user_display_name: string
  atm_code: string
}

const initialState: AuthStateInterface = {
  isLoggedIn: false,
  loading: false,
  user_display_name: '',
  atm_code: '',
}

export const loginThunk = createAsyncThunk<LoginResponseDataInterface, LoginFormInterface>(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await loginRequest(data)
      persistTokenData(response.data)
      return response.data
    } catch (e) {
      return rejectWithValue((e as Error).message)
    }
  },
)

export const validateTokenThunk = createAsyncThunk<{ atm_code: string; user_display_name: string }, string>(
  'auth/validate',
  async (token, { rejectWithValue }) => {
    try {
      await validateTokenRequest(token)
      return getUserData()
    } catch (e: any) {
      removeTokenData()
      return rejectWithValue(e.code)
    }
  },
)

export const logoutThunk = createAsyncThunk<void, void>('auth/logout', async () => {
  removeTokenData()
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.atm_code = action.payload.atm_code
      state.user_display_name = action.payload.user_display_name
      state.isLoggedIn = true
    })
    builder.addCase(validateTokenThunk.fulfilled, (state, action) => {
      state.atm_code = action.payload.atm_code
      state.user_display_name = action.payload.user_display_name
      state.isLoggedIn = true
    })
    builder.addCase(logoutThunk.fulfilled, () => initialState)

    builder.addMatcher(isPendingAction, state => {
      state.loading = true
    })
    builder.addMatcher(isRejectedAction, state => {
      state.isLoggedIn = false
      state.loading = false
    })
    builder.addMatcher(isFulfilledAction, state => {
      state.loading = false
    })
  },
})

const sliceSelector = (state: RootState) => state.auth

export const selectLoggedIn = createSelector(sliceSelector, state => state.isLoggedIn)
export const selectUserData = createSelector(sliceSelector, state => ({
  user_display_name: state.user_display_name,
  atm_code: state.atm_code,
}))
export const selectLoading = createSelector(sliceSelector, state => state.loading)

export default {
  name: authSlice.name,
  reducer: authSlice.reducer,
}
