import { FC, MouseEvent, useMemo, useState } from 'react'
import { Box, Button, Menu, MenuItem, Stack, ToggleButton, Typography, useTheme } from '@mui/material'

import WindowFrame from 'components/Windows/WindowFrame'

import { useAppDispatch, useAppSelector } from 'store/store'
import { toggleWindow } from 'store/windowsSlice'
import { selectActiveRwys, setActiveRwys } from 'store/exerciseSlice'
import { selectAirports } from 'store/navDataSlice'

const RunwaysWindow: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const airports = useAppSelector(selectAirports)
  const activeRwys = useAppSelector(selectActiveRwys)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const airportsList = Object.keys(airports)
  const [selectedAirport, setSelectedAirport] = useState<string>(airportsList[0])

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSelect = (value: string) => () => {
    setSelectedAirport(value)
    setAnchorEl(null)
  }

  const mappedAirports = useMemo(() => {
    const data: Record<string, string[]> = {}

    airportsList.forEach(airport => {
      const runways = airports[airport].Runways
      data[airport] = []
      Object.keys(runways).forEach(runway => {
        data[airport].push(runway)
      })
    })
    return data
  }, [airportsList, airports])

  const handleClose = () => {
    dispatch(toggleWindow('rwyWindow'))
  }

  const handleToggleRWY = (_: MouseEvent<HTMLElement>, value: string) => {
    dispatch(setActiveRwys({ [selectedAirport]: value }))
  }

  return (
    <WindowFrame title="Runways" onClose={handleClose} zIndex={theme.zIndex.drawer}>
      <Menu
        open={open}
        onClose={handleCloseMenu}
        sx={{ marginTop: theme.spacing(1) }}
        anchorEl={anchorEl}
        className="no-drag"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {airportsList.map(airport => (
          <MenuItem key={airport} onClick={handleSelect(airport)}>
            {airport}
          </MenuItem>
        ))}
      </Menu>
      <Button onClick={handleOpen} fullWidth>
        {selectedAirport}
      </Button>
      <Stack direction="column" gap={theme.spacing(0.5)} display="flex" my={theme.spacing(1)}>
        {mappedAirports[selectedAirport].map(rwy => (
          <Box key={rwy} display="flex" alignItems="center" gap={theme.spacing(1)}>
            <Typography color="primary.contrastText" display="inline-block">
              {rwy}
            </Typography>
            <ToggleButton
              color="info"
              value={rwy}
              selected={activeRwys[selectedAirport] === rwy}
              onClick={handleToggleRWY}
            >
              ARR/DEP
            </ToggleButton>
          </Box>
        ))}
      </Stack>
    </WindowFrame>
  )
}

export default RunwaysWindow
