import { FC, useRef } from 'react'
import { useAppSelector } from 'store/store'
import { selectExerciseFPLs } from 'store/exerciseSlice'
import Track from 'components/Track/Track'

const TrackLayer: FC = () => {
  const tracks = useAppSelector(selectExerciseFPLs)
  const svgRef = useRef<SVGSVGElement>(null)

  return (
    <svg
      ref={svgRef}
      width="100%"
      height="100%"
      style={{ position: 'absolute', top: 0, left: 0, zIndex: 10, userSelect: 'none' }}
    >
      <defs>
        <polygon id="target" points="4,0 8,4 4,8 0,4" height={8} width={8} fill="none" strokeWidth="1px" />
        <polygon id="point" points="3,0 6,3 3,6 0,3" height={6} width={6} />
      </defs>
      {tracks.map(track => (
        <Track key={track.id} track={track} />
      ))}
    </svg>
  )
}

export default TrackLayer
