import { ChangeEvent, FC, FormEvent, useState } from 'react'
import { Box, Button, Stack, styled, TextField, useTheme } from '@mui/material'

import { useAppDispatch } from 'store/store'
import { loginThunk } from 'store/authSlice'

const FormWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FormComponent = styled('form')`
  flex-basis: 30%;
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  border: 1px solid ${({ theme }) => theme.palette.grayscale.black};
`

const LoginForm: FC = () => {
  const [formData, setFormData] = useState<LoginFormInterface>({} as LoginFormInterface)
  const [error, setError] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError(false)
    }
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()
      await dispatch(loginThunk(formData)).unwrap()
      setFormData({} as LoginFormInterface)
    } catch (e) {
      setError(true)
    }
  }

  return (
    <FormWrapper>
      <FormComponent onSubmit={onSubmit}>
        <Stack direction="column" gap={theme.spacing(3)}>
          <TextField
            label="User name"
            variant="outlined"
            name="username"
            value={formData.username || ''}
            onChange={handleInputChange}
            error={error}
          />
          <TextField
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            value={formData.password || ''}
            onChange={handleInputChange}
            error={error}
            helperText={error && 'Invalid Login or Password'}
          />
          <Button type="submit" fullWidth>
            Login
          </Button>
        </Stack>
      </FormComponent>
    </FormWrapper>
  )
}

export default LoginForm
