import { FC } from 'react'
import { NestedMenuItem } from 'mui-nested-menu'
import { Button, MenuItem, useTheme } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'store/store'
import {
  selectFilled,
  selectLabelFontSize,
  selectLabelLines,
  setLabelFontSize,
  toggleLabelLine,
  toggleSectorFilled,
} from 'store/configSlice'

import useMenu from 'hooks/useMenu'
import MenuItemWithCheckbox from 'components/Windows/components/MenuItemWithCheckbox'

const fontSizes: Record<string, number> = {
  small: 8,
  medium: 12,
  large: 16,
}

const FilledItem: FC = () => {
  const dispatch = useAppDispatch()
  const filled = useAppSelector(selectFilled)

  const handleCheckboxChange = () => {
    dispatch(toggleSectorFilled())
  }

  return <MenuItemWithCheckbox label="Filled" checkboxName="filled" onChange={handleCheckboxChange} checked={filled} />
}

const FontSizeItem: FC<{ isParentOpen: boolean }> = ({ isParentOpen }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const fontSize = useAppSelector(selectLabelFontSize)

  const handleSizeChange = (size: number) => () => {
    dispatch(setLabelFontSize(size))
  }

  return (
    <NestedMenuItem parentMenuOpen={isParentOpen} label="Label Font Size">
      {Object.entries(fontSizes).map(([key, value]) => (
        <MenuItem
          key={key}
          onClick={handleSizeChange(value)}
          sx={{ backgroundColor: fontSize === value ? theme.palette.primary.dark : 'initial' }}
        >
          {key.toUpperCase()}
        </MenuItem>
      ))}
    </NestedMenuItem>
  )
}

const LabelLinesItem: FC<{ isParentOpen: boolean }> = ({ isParentOpen }) => {
  const dispatch = useAppDispatch()
  const { three, four } = useAppSelector(selectLabelLines)

  const handleCheck = (name: string) => {
    dispatch(toggleLabelLine(name))
  }

  return (
    <NestedMenuItem parentMenuOpen={isParentOpen} label="Label Size">
      <MenuItemWithCheckbox checkboxName="three" onChange={handleCheck} checked={three} label="Line 3" />
      <MenuItemWithCheckbox checkboxName="four" onChange={handleCheck} checked={four} label="Line 4" />
    </NestedMenuItem>
  )
}

const ConfigMenu: FC = () => {
  const { Menu, openMenu, isMenuOpen } = useMenu()

  return (
    <>
      <Button onClick={openMenu}>Config</Button>
      <Menu>
        <LabelLinesItem isParentOpen={isMenuOpen} />
        <FontSizeItem isParentOpen={isMenuOpen} />
        <FilledItem />
      </Menu>
    </>
  )
}

export default ConfigMenu
