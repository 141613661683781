import { FC } from 'react'
import { Box } from '@mui/material'

import MenuItemWithCheckbox from 'components/Windows/components/MenuItemWithCheckbox'

import {
  selectFreqState,
  selectLinesState,
  selectPointsState,
  selectSectorsState,
  toggleFreqs,
  toggleLine,
  togglePoints,
  toggleSector,
} from 'store/mapSlice'
import { useAppDispatch, useAppSelector } from 'store/store'

const selectors = {
  sectors: selectSectorsState,
  lines: selectLinesState,
  points: selectPointsState,
  freq: selectFreqState,
}

const togglers = {
  sectors: toggleSector,
  lines: toggleLine,
  points: togglePoints,
  freq: toggleFreqs,
}

const MenuDir: FC<{ section: MapVariants }> = ({ section }) => {
  const selector = selectors[section]
  // TODO: fix 'any' and ts-ignore
  const data = useAppSelector<any>(selector)
  const dispatch = useAppDispatch()
  const toggler = togglers[section]

  const handleCheckboxChange = (value: string) => {
    // @ts-ignore
    dispatch(toggler(value))
  }
  // -----

  return (
    <Box className="no-drag">
      {Object.entries(data).map(([key, value]) => (
        <MenuItemWithCheckbox
          key={key}
          label={key.toUpperCase()}
          checkboxName={key}
          onChange={handleCheckboxChange}
          checked={value as boolean}
        />
      ))}
    </Box>
  )
}

export default MenuDir
