import { FC, useRef } from 'react'
import { GeoProjection } from 'd3-geo'

import RoutePoint from 'components/Route/RoutePoint'

import { geoToScreenCoordinates } from 'utils/map'

const Route: FC<{ current: number[]; nextPointIndex: number; route: TrackRoutePoint[]; projection: GeoProjection }> = ({
  nextPointIndex = 0,
  route,
  current,
  projection,
}) => {
  const routeLeft = route.filter((_, index) => index >= nextPointIndex)
  const segmentEndCoords = useRef<PointInterface[]>([])

  return (
    <g>
      {routeLeft.map((point, index) => {
        const startGeo = segmentEndCoords.current[index - 1]
        const start = index === 0 ? current : geoToScreenCoordinates(startGeo, projection)
        const endGeo = { latitude: point.latitude, longitude: point.longitude }
        const end = geoToScreenCoordinates(endGeo, projection)
        segmentEndCoords.current[index] = endGeo

        return (
          <RoutePoint
            key={`${point.name}-${Math.random()}`}
            start={start}
            end={end}
            name={point.name}
            tickOver={point.tickOver}
          />
        )
      })}
    </g>
  )
}

export default Route
