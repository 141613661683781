class AirspeedConverter {
  private sigFig: number

  // Define the standard atmospheric values that remain constant
  // pressure
  private P0: number

  // is the speed of sound at standard sea level (661.47 knots (1,225.04 km/h; 340.29 m/s)),
  private a0: number

  // air density at sea level in the stand.atm (15 °C and 1013.25 hectopascals, corresponding to a density of 1.225 kg/m3),
  private rho0: number

  // define the actual atm variables as global variables

  private sonicSI: number

  private pressureSI: number

  private rhoSI: number

  private sigma: number

  // define the various speeds in SI units
  private cas: number

  private eas: number

  private tas: number

  private mach: number

  constructor() {
    this.sigFig = 1000
    this.P0 = 101325
    this.a0 = 340.29
    this.rho0 = 1.225

    this.sonicSI = 0
    this.pressureSI = 0
    this.rhoSI = 0
    this.sigma = 0

    this.cas = 0
    this.eas = 0
    this.tas = 0
    this.mach = 0
  }

  calcSpeedsFromCAS(casInput: number, altRaw: number) {
    this.atmCalc(altRaw) // calc atm parameters

    const velFactor = 0.514444444

    this.cas = velFactor * casInput

    const qc = this.P0 * ((((this.cas / this.a0) ** 2 + 5) / 5) ** (7 / 2) - 1)

    this.mach = (5 * ((qc / this.pressureSI + 1) ** (2 / 7) - 1)) ** 0.5
    this.tas = this.mach * this.sonicSI

    this.eas = this.tas / (this.rho0 / this.rhoSI) ** 0.5

    return this.convertFromSI() // convert results from SI to whatever units are required by the end user
  }

  calcSpeedsFromMach(machInput: number, altRaw: number) {
    this.atmCalc(altRaw) // calc atm parameters

    this.mach = machInput
    this.tas = this.mach * this.sonicSI
    this.eas = this.tas / (this.rho0 / this.rhoSI) ** 0.5

    const qc = this.pressureSI * ((1 + 0.2 * this.mach ** 2) ** (7 / 2) - 1)
    this.cas = this.a0 * (5 * ((qc / this.P0 + 1) ** (2 / 7) - 1)) ** 0.5

    return this.convertFromSI() // convert results from SI to whatever units are required by the end user
  }

  private convertFromSI() {
    const unitFactor = 1.94384

    const outputCAS = Math.round(this.sigFig * this.cas * unitFactor) / this.sigFig
    const outputEAS = Math.round(this.sigFig * this.eas * unitFactor) / this.sigFig
    const outputTAS = Math.round(this.sigFig * this.tas * unitFactor) / this.sigFig
    const outputMach = Math.round(this.sigFig * this.mach) / this.sigFig

    return {
      mach: outputMach,
      cas: outputCAS,
      tas: outputTAS,
      eas: outputEAS,
    }
  }

  private atmCalc(altRaw: number) {
    const altSI = altRaw * 0.3048

    const altitudeArray = [0, 11000, 20000, 32000, 47000, 51000, 71000, 84852]
    const presRelsArray = [
      // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
      1, 2.23361105092158e-1, 5.403295010784876e-2, 8.566678359291667e-3, 1.0945601337771144e-3, 6.606353132858367e-4,
      3.904683373343926e-5, 3.6850095235747942e-6,
    ]
    const tempsArray = [288.15, 216.65, 216.65, 228.65, 270.65, 270.65, 214.65, 186.946]
    const tempGradArray = [-6.5, 0, 1, 2.8, 0, -2.8, -2, 0]

    let i = 0
    while (altSI > altitudeArray[i + 1]) {
      i += 1
    }

    // i defines the array position that I require for the calc
    const alts = altitudeArray[i]
    const presRels = presRelsArray[i]
    const temps = tempsArray[i]
    const tempGrad = tempGradArray[i] / 1000

    const deltaAlt = altSI - alts
    const stdTemp = temps + deltaAlt * tempGrad // this is the standard temperature at STP

    // Now for the relative pressure calc:

    // define constants
    const airMol = 28.9644
    const rhoSL = 1.225 // kg/m3
    const pSL = 101325 // Pa
    const tSL = 288.15 // K
    const gamma = 1.4
    const g = 9.80665 // m/s2
    const rGas = 8.31432 // kg/Mol/K
    const R = 287.053
    const gMR = (g * airMol) / rGas

    let relPres: number
    if (Math.abs(tempGrad) < 1e-10) {
      relPres = presRels * Math.exp((-1 * gMR * deltaAlt) / 1000 / temps)
    } else {
      relPres = presRels * (temps / stdTemp) ** (gMR / tempGrad / 1000)
    }

    // Now I can calculate the results:
    this.sonicSI = Math.sqrt(gamma * R * stdTemp)
    this.pressureSI = pSL * relPres
    this.rhoSI = rhoSL * relPres * (tSL / stdTemp)
    this.sigma = this.rhoSI / rhoSL
  }
}

export default new AirspeedConverter()
