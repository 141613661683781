import { FC, useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Box, createTheme, CssBaseline, styled, ThemeProvider } from '@mui/material'

import store from 'store'

import { theme as customTheme } from './theme/theme'

import AppRouter from './routes/AppRouter'

const AppContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.background.default};
`

const App: FC = () => {
  const appTheme = createTheme(customTheme)

  const disableContextMenu = (e: MouseEvent) => {
    e.preventDefault()
  }
  const disableWheelClick = (e: MouseEvent) => {
    if (e.buttons === 4) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    document.body.addEventListener('contextmenu', disableContextMenu, false)
    document.body.addEventListener('mousedown', disableWheelClick, true)

    return () => {
      document.body.removeEventListener('contextmenu', disableContextMenu)
      document.body.removeEventListener('mousedown', disableWheelClick)
    }
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <AppContainer>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </AppContainer>
      </ThemeProvider>
    </Provider>
  )
}

export default App
