import { FC, MouseEvent } from 'react'
import { Stack, ToggleButton, useTheme } from '@mui/material'

import WindowFrame from 'components/Windows/WindowFrame'

import { useAppDispatch, useAppSelector } from 'store/store'
import { toggleWindow } from 'store/windowsSlice'
import { selectVector, setVectorValue, toggleVector, toggleVectorMarks } from 'store/configSlice'

const SpeedVectorWindow: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { vectorValue, isVectorShown, isVectorMarksShown } = useAppSelector(selectVector)
  const sVButtonValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20]

  const handleValueChange = (_: MouseEvent<HTMLElement>, value: number) => {
    dispatch(setVectorValue(value))
  }

  const handleVectorToggle = () => {
    dispatch(toggleVector())
  }

  const handleVectorMarksToggle = () => {
    dispatch(toggleVectorMarks())
  }

  const handleClose = () => {
    dispatch(toggleWindow('speedVectorWindow'))
  }

  return (
    <WindowFrame title="Speed Vector" onClose={handleClose} position={{ x: 370, y: 130 }} zIndex={theme.zIndex.drawer}>
      <ToggleButton
        value="speedVectorWindow"
        selected={isVectorShown}
        onClick={handleVectorToggle}
        sx={{ mr: theme.spacing(1) }}
      >
        {isVectorShown ? 'OFF' : 'ON'}
      </ToggleButton>
      <Stack direction="row" gap={theme.spacing(0.5)} display="inline-flex">
        {sVButtonValue.map(value => (
          <ToggleButton
            key={value}
            value={value}
            selected={vectorValue === value}
            onClick={handleValueChange}
            size="small"
          >
            {value}
          </ToggleButton>
        ))}
        <ToggleButton
          value="speedVectorWindow"
          selected={isVectorMarksShown}
          onClick={handleVectorMarksToggle}
          sx={{ lr: theme.spacing(1) }}
        >
          Dot
        </ToggleButton>
      </Stack>
    </WindowFrame>
  )
}

export default SpeedVectorWindow
